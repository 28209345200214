import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    Button
} from "shards-react";

const UserAccountDetails = ({ title }) => (
    <Card small className="mb-4">
        <CardHeader className="border-bottom">
            <h6 className="m-0">screen.hr app support request</h6>
        </CardHeader>
        <ListGroup flush>
            <ListGroupItem className="p-3">
                <Row>
                    <Col>
                        <Form>
                            <Row form>
                                {/* First Name */}
                                <Col md="6" className="form-group">
                                    <label htmlFor="feFirstName">First Name</label>
                                    <FormInput
                                        id="feFirstName"
                                        placeholder="First Name"
                                    />
                                </Col>
                                {/* Last Name */}
                                <Col md="6" className="form-group">
                                    <label htmlFor="feLastName">Last Name</label>
                                    <FormInput
                                        id="feLastName"
                                        placeholder="Last Name"
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                {/* Email */}
                                <Col className="form-group">
                                    <label htmlFor="feEmail">Email</label>
                                    <FormInput
                                        type="email"
                                        id="feEmail"
                                        value={'support@screen.hr'}
                                        placeholder="Email Address"
                                        autoComplete="email"
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                {/* Description */}
                                <Col md="12" className="form-group">
                                    <label htmlFor="feDescription">Description</label>
                                    <FormTextarea id="feDescription" rows="5" />
                                </Col>
                            </Row>
                            <Button theme="accent" onClick={(e) => {
                                window.location = `mailto:${document.getElementById('feEmail').value}?body=${document.getElementById('feDescription').value + ' ' + document.getElementById('feFirstName').value + ' ' + document.getElementById('feLastName').value}`
                                e.preventDefault()
                            }}>Get in touch
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </ListGroupItem>
        </ListGroup>
    </Card>
);

UserAccountDetails.propTypes = {
    /**
     * The component's title.
     */
    title: PropTypes.string
};

UserAccountDetails.defaultProps = {
    title: "Account Details"
};

export default UserAccountDetails;
