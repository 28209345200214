import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  ListGroupItem,
  Form,
  FormSelect,
  FormInput,
  ListGroup,
} from "shards-react";
import MUIDataTable from "mui-datatables";
import { compose } from "recompose";
import { withFirebase } from "../components/Firebase";
import PageTitle from "../components/common/PageTitle";
import CustomToolbar from "../components/common/CustomToolbar";
import RangeDatePicker from "../components/common/RangeDatePicker";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "react-loader-spinner";
import { ReactComponent as RefreshButton } from "../assets/refresh-ccw.svg";

function ClientJobDetail(props) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [mathFilterChecked, setMathFilterChecked] = useState(false);
  const [videoFilterChecked, setVideoFilterChecked] = useState(false);
  const [audioFilterChecked, setAudioFilterChecked] = useState(false);
  const [englishFilterChecked, setEnglishFilterChecked] = useState(false);
  const [
    logicalReasoningFilterChecked,
    setLogicalReasoningFilterChecked,
  ] = useState(false);
  const [totalScoreFilterChecked, setTotalScoreFilterChecked] = useState(false);
  const [assesorScoreFilterChecked, setAssesorScoreFilterChecked] = useState(
    false
  );
  const [remainedCredits, setRemainedCredits] = useState(0);
  const [spentTableData, setSpentTableData] = useState([]);
  const [totalSpentCredits, setTotalSpentCredits] = useState(0);

  const [noReply, setNoReply] = useState(0);
  const [unmarked, setUnmarked] = useState(0);
  const [selected, setSelected] = useState(0);
  const [notSelected, setNotSelected] = useState(0);
  const [daysUntilDeadline, setDaysUntilDeadline] = useState(0);

  const columns = [
    {
      name: "Username",
      options: {
        filter: true,
      },
    },
    {
      name: "English",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && englishFilterChecked) {
              return [`Min English: ${v[0]}`, `Max English: ${v[1]}`];
            } else if (v[0] && v[1] && !englishFilterChecked) {
              return `Min English: ${v[0]}, Max English: ${v[1]}`;
            } else if (v[0]) {
              return `Min English: ${v[0]}`;
            } else if (v[1]) {
              return `Max English: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(english, filters) {
            if (filters[0] && filters[1]) {
              return english < filters[0] || english > filters[1];
            } else if (filters[0]) {
              return english < filters[0];
            } else if (filters[1]) {
              return english > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>English</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={englishFilterChecked}
                      onChange={(event) =>
                        setEnglishFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Logical Reasoning",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && logicalReasoningFilterChecked) {
              return [`Min Reasoning: ${v[0]}`, `Max Reasoning: ${v[1]}`];
            } else if (v[0] && v[1] && !logicalReasoningFilterChecked) {
              return `Min Reasoning: ${v[0]}, Max Reasoning: ${v[1]}`;
            } else if (v[0]) {
              return `Min Reasoning: ${v[0]}`;
            } else if (v[1]) {
              return `Max Reasoning: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(logicalReasoningFilterChecked, filters) {
            if (filters[0] && filters[1]) {
              return (
                logicalReasoningFilterChecked < filters[0] ||
                logicalReasoningFilterChecked > filters[1]
              );
            } else if (filters[0]) {
              return logicalReasoningFilterChecked < filters[0];
            } else if (filters[1]) {
              return logicalReasoningFilterChecked > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>Logical Reasoning</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={logicalReasoningFilterChecked}
                      onChange={(event) =>
                        setLogicalReasoningFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Math",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && mathFilterChecked) {
              return [`Min Math: ${v[0]}`, `Max Math: ${v[1]}`];
            } else if (v[0] && v[1] && !mathFilterChecked) {
              return `Min Math: ${v[0]}, Max Math: ${v[1]}`;
            } else if (v[0]) {
              return `Min Math: ${v[0]}`;
            } else if (v[1]) {
              return `Max Math: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(math, filters) {
            if (filters[0] && filters[1]) {
              return math < filters[0] || math > filters[1];
            } else if (filters[0]) {
              return math < filters[0];
            } else if (filters[1]) {
              return math > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>Math</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mathFilterChecked}
                      onChange={(event) =>
                        setMathFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Audio",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && audioFilterChecked) {
              return [`Min Audio: ${v[0]}`, `Max Audio: ${v[1]}`];
            } else if (v[0] && v[1] && !audioFilterChecked) {
              return `Min Audio: ${v[0]}, Max Audio: ${v[1]}`;
            } else if (v[0]) {
              return `Min Audio: ${v[0]}`;
            } else if (v[1]) {
              return `Max Audio: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(audio, filters) {
            if (filters[0] && filters[1]) {
              return audio < filters[0] || audio > filters[1];
            } else if (filters[0]) {
              return audio < filters[0];
            } else if (filters[1]) {
              return audio > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>Audio</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={audioFilterChecked}
                      onChange={(event) =>
                        setAudioFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Video",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && videoFilterChecked) {
              return [`Min Video: ${v[0]}`, `Max Video: ${v[1]}`];
            } else if (v[0] && v[1] && !videoFilterChecked) {
              return `Min Video: ${v[0]}, Max Video: ${v[1]}`;
            } else if (v[0]) {
              return `Min Video: ${v[0]}`;
            } else if (v[1]) {
              return `Max Video: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(video, filters) {
            if (filters[0] && filters[1]) {
              return video < filters[0] || video > filters[1];
            } else if (filters[0]) {
              return video < filters[0];
            } else if (filters[1]) {
              return video > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>Video</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={videoFilterChecked}
                      onChange={(event) =>
                        setVideoFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Total Score",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && totalScoreFilterChecked) {
              return [`Min Total Score: ${v[0]}`, `Max Total Score: ${v[1]}`];
            } else if (v[0] && v[1] && !totalScoreFilterChecked) {
              return `Min Total Score: ${v[0]}, Max Total Score: ${v[1]}`;
            } else if (v[0]) {
              return `Min Total Score: ${v[0]}`;
            } else if (v[1]) {
              return `Max Total Score: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(totalScore, filters) {
            if (filters[0] && filters[1]) {
              return totalScore < filters[0] || totalScore > filters[1];
            } else if (filters[0]) {
              return totalScore < filters[0];
            } else if (filters[1]) {
              return totalScore > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>Total Score</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={totalScoreFilterChecked}
                      onChange={(event) =>
                        setTotalScoreFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Assesor Score",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && assesorScoreFilterChecked) {
              return [`Min Total Score: ${v[0]}`, `Max Total Score: ${v[1]}`];
            } else if (v[0] && v[1] && !assesorScoreFilterChecked) {
              return `Min Total Score: ${v[0]}, Max Total Score: ${v[1]}`;
            } else if (v[0]) {
              return `Min Total Score: ${v[0]}`;
            } else if (v[1]) {
              return `Max Total Score: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(assesorScore, filters) {
            if (filters[0] && filters[1]) {
              return assesorScore < filters[0] || assesorScore > filters[1];
            } else if (filters[0]) {
              return assesorScore < filters[0];
            } else if (filters[1]) {
              return assesorScore > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel style={{ color: "black" }}>
                <strong>Assesor's Subjective Score</strong>
              </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assesorScoreFilterChecked}
                      onChange={(event) =>
                        setAssesorScoreFilterChecked(event.target.checked)
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: true,
        selectableRows: true,
        filterType: "checkbox",
      },
    },
    {
      name: "Test Result",
      options: {
        filter: true,
        selectableRows: true,
        filterType: "checkbox",
      },
    },
    {
      name: "Assesor notes",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <RefreshButton
        stroke="gray"
        style={{ cursor: "pointer", width: "3%", height: "60%" }}
        onClick={async () => {
          let job = props.location.state.job;
          let candidatesForJob = job.candidatesForTest1;
          let resultsForCandidates = await props.firebase.doGetCandidatesResults(
            candidatesForJob
          );
          // let resultsForCandidates = []
          let results = [];
          resultsForCandidates.forEach((candidate) => {
            results.push({
              candidateUsername: candidate.candidateUsername,
              englishScore: candidate.englishScore.toString(),
              logicalReasoningScore: candidate.logicalReasoningScore.toString(),
              mathScore: candidate.mathScore.toString(),
              video1: candidate.video1,
              video2: candidate.video2,
              video3: candidate.video3,
              audio1: candidate.audio1,
              audio2: candidate.audio2,
              video1Score: candidate.video1Score,
              video2Score: candidate.video2Score,
              video3Score: candidate.video3Score,
              audio1Score: candidate.audio1Score,
              audio2Score: candidate.audio2Score,
              profilePicture: candidate.profilePicture,
              profilePicture2: candidate.profilePicture2,
              idPicture: candidate.idPicture,
              notes: candidate.notes,
              assesorScore:
                typeof candidate.assesorScore == "undefined"
                  ? 0
                  : candidate.assesorScore,
              assesment: candidate.assesment,
              candidateNumber: candidate.candidateNumber.toString(),
              email: candidate.email,
              firstName: candidate.firstName,
              lastName: candidate.lastName,
              dateOfBirth: candidate.dateOfBirth,
              city: candidate.city,
              age: candidate.age.toString(),
              uid: candidate.uid,
              scores: candidate.scores,
            });
          });
          setData(results);
          console.log("Results: ", results);
          let tableRowsData = [];

          results.forEach((result) => {
            let totalScore = Math.round(
              (parseInt(result.englishScore) +
                parseInt(result.logicalReasoningScore) +
                parseInt(result.mathScore) +
                Math.round(
                  (Math.round((result.audio1Score + result.audio2Score) / 2) *
                    100) /
                    5
                ) +
                Math.round(
                  (Math.round((result.video1Score + result.video2Score) / 2) *
                    100) /
                    5
                )) /
                5
            );

            tableRowsData.push([
              result.candidateUsername,
              result.englishScore,
              result.logicalReasoningScore,
              result.mathScore,
              Math.round(
                (Math.round((result.audio1Score + result.audio2Score) / 2) *
                  100) /
                  5
              ),
              Math.round(
                (Math.round((result.video1Score + result.video2Score) / 2) *
                  100) /
                  5
              ),
              totalScore,
              result.assesorScore,
              result.assesment == "Test not taken"
                ? "No results"
                : result.assesment,
              result.assesment == "Unmarked" ||
              result.assesment == "Test not taken"
                ? "N/A"
                : totalScore < 50
                ? "Failed"
                : "Passed",
              result.notes,
            ]);
          });
          setTableData(tableRowsData);
        }}
      />
      {/*<img src={RefreshButton} style={{cursor: 'pointer', color: 'dark-grey'}} onClick={() => {*/}
      {/*  */}
      {/*}}/>*/}
    </>
  );

  const options = {
    customToolbar: () => <HeaderElements />,
    filterType: "textField",
    sort: true,
    downloadOptions: { filterOptions: { useDisplayedRowsOnly: true } },
    draggableColumns: { enabled: true },
    print: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    setRowProps: (row) => {
      if (row[0]) {
        return {
          style: { cursor: "pointer" },
        };
      }
    },
    onRowClick: (rowData, index) => {
      if (remainedCredits - totalSpentCredits <= 0) {
        alert("Insufficient credits, please contact screen.hr.");
      } else {
        let results = [];
        results.push({
          candidateUsername: rowData[0],
          englishScore: rowData[1],
          logicalReasoningScore: rowData[2],
          mathScore: rowData[3],
          video1: data[index.dataIndex].video1,
          video2: data[index.dataIndex].video2,
          video3: data[index.dataIndex].video3,
          audio1: data[index.dataIndex].audio1,
          audio2: data[index.dataIndex].audio2,
          video1Score: data[index.dataIndex].video1Score,
          video2Score: data[index.dataIndex].video2Score,
          video3Score: data[index.dataIndex].video3Score,
          audio1Score: data[index.dataIndex].audio1Score,
          audio2Score: data[index.dataIndex].audio2Score,
          profilePicture: data[index.dataIndex].profilePicture,
          profilePicture2: data[index.dataIndex].profilePicture2,
          idPicture: data[index.dataIndex].idPicture,
          notes: data[index.dataIndex].notes,
          assesorScore:
            typeof data[index.dataIndex].assesorScore == "undefined"
              ? 0
              : data[index.dataIndex].assesorScore,
          assesment: data[index.dataIndex].assesment,
          candidateNumber: data[index.dataIndex].candidateNumber,
          email: data[index.dataIndex].email,
          firstName: data[index.dataIndex].firstName,
          lastName: data[index.dataIndex].lastName,
          city: data[index.dataIndex].city,
          dateOfBirth: data[index.dataIndex].dateOfBirth,
          age: data[index.dataIndex].age,
          uid: data[index.dataIndex].uid,
          scores: data[index.dataIndex].scores,
        });
        props.history.push({
          pathname: "/candidate-detail",
          state: { candidates: results, jobData: props.location.state.job },
        });
      }
    },
    onRowsSelect: (rowsSelected, allRows) => {
      const selected = allRows.map((item) => item.index);
      setSelectedRows(selected);
    },
    responsive: "scroll",
    fixedHeader: true,
    customToolbarSelect: () => {
      return selectedRows.length !== 0 ? (
        <Button
          size="md"
          theme="primary"
          style={{ marginRight: "20px" }}
          onClick={() => {
            let results = [];
            selectedRows.forEach((index) => {
              results.push({
                candidateUsername: data[index].candidateUsername,
                englishScore: data[index].englishScore,
                logicalReasoningScore: data[index].logicalReasoningScore,
                mathScore: data[index].mathScore,
                video1: data[index].video1,
                video2: data[index].video2,
                video3: data[index].video3,
                audio1: data[index].audio1,
                audio2: data[index].audio2,
                video1Score: data[index].video1Score,
                video2Score: data[index].video2Score,
                video3Score: data[index].video3Score,
                audio1Score: data[index].audio1Score,
                audio2Score: data[index].audio2Score,
                profilePicture: data[index].profilePicture,
                profilePicture2: data[index].profilePicture2,
                idPicture: data[index].idPicture,
                notes: data[index].notes,
                assesorScore:
                  typeof data[index].assesorScore == "undefined"
                    ? 0
                    : data[index].assesorScore,
                assesment: data[index].assesment,
                candidateNumber: data[index].candidateNumber,
                email: data[index].email,
                firstName: data[index].firstName,
                lastName: data[index].lastName,
                city: data[index].city,
                dateOfBirth: data[index].dateOfBirth,
                age: data[index].age,
                uid: data[index].uid,
                scores: data[index].scores,
              });
            });
            props.history.push({
              pathname: "/candidate-detail",
              state: { candidates: results, jobData: props.location.state.job },
            });
          }}
        >
          <i /> Candidates overview for selected results
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    async function getData() {
      props.firebase
        .doGetCurrentAirline()
        .then((res) => setRemainedCredits(res["creditsLeft"]));
      let creditResults = [];
      props.firebase.doGetMyCreditsPurchases().then((res) =>
        res.forEach((purchase) => {
          creditResults.push({
            creditsNumber: purchase.creditsNumber.toString(),
            date: purchase.date,
            note: purchase.note,
          });
        })
      );

      let creditRowsData = [];
      creditResults.forEach((result) => {
        creditRowsData.push([result.date, result.creditsNumber, result.note]);
      });
      setTableData(creditRowsData);

      let spentCreditss = await props.firebase.doGetMySpentCreditsNumber();

      props.firebase
        .doGetMySpentCredits(spentCreditss.number, spentCreditss.infos)
        .then((spent) => {
          let spentResults = [];
          spent.forEach((credit) => {
            spentResults.push({
              date: credit["candidateData"].date,
              testName: credit["candidateData"].testName,
              candidateUsername: credit["candidateData"].candidateUsername,
              creditCost: credit["creditCost"],
            });
          });
          let spentTableRowsData = [];
          spentResults.forEach((result) => {
            spentTableRowsData.push([
              result.date,
              result.testName,
              result.candidateUsername,
              result.creditCost,
            ]);
          });
          setSpentTableData(spentTableRowsData);
          let spentCredits = 0;
          spentTableRowsData.forEach((row) => {
            spentCredits = spentCredits + row[3];
          });
          setTotalSpentCredits(spentCredits);
        });

      let job = props.location.state.job;
      let candidatesForJob = job.candidatesForTest1;
      console.log("HEHE:===============================? ", candidatesForJob);
      let resultsForCandidates = await props.firebase.doGetCandidatesResults(
        candidatesForJob
      );
      console.log(
        "CANDID ---------------------------------------------: ",
        resultsForCandidates
      );
      // let resultsForCandidates = []
      let results = [];
      resultsForCandidates.forEach((candidate) => {
        results.push({
          candidateUsername: candidate.candidateUsername,
          englishScore: candidate.englishScore.toString(),
          logicalReasoningScore: candidate.logicalReasoningScore.toString(),
          mathScore: candidate.mathScore.toString(),
          video1: candidate.video1,
          video2: candidate.video2,
          video3: candidate.video3,
          audio1: candidate.audio1,
          audio2: candidate.audio2,
          video1Score: candidate.video1Score,
          video2Score: candidate.video2Score,
          video3Score: candidate.video3Score,
          audio1Score: candidate.audio1Score,
          audio2Score: candidate.audio2Score,
          profilePicture: candidate.profilePicture,
          profilePicture2: candidate.profilePicture2,
          idPicture: candidate.idPicture,
          notes: candidate.notes,
          assesorScore:
            typeof candidate.assesorScore == "undefined"
              ? 0
              : candidate.assesorScore,
          assesment: candidate.assesment,
          candidateNumber: candidate.candidateNumber.toString(),
          email: candidate.email,
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          dateOfBirth: candidate.dateOfBirth,
          city: candidate.city,
          age: candidate.age.toString(),
          uid: candidate.uid,
          scores: candidate.scores,
        });
      });
      setData(results);
      console.log("Results: ", results);
      let tableRowsData = [];

      results.forEach((result) => {
        let totalScore = Math.round(
          (parseInt(result.englishScore) +
            parseInt(result.logicalReasoningScore) +
            parseInt(result.mathScore) +
            Math.round(
              (Math.round((result.audio1Score + result.audio2Score) / 2) *
                100) /
                5
            ) +
            Math.round(
              (Math.round((result.video1Score + result.video2Score) / 2) *
                100) /
                5
            )) /
            5
        );

        tableRowsData.push([
          result.candidateUsername,
          result.englishScore,
          result.logicalReasoningScore,
          result.mathScore,
          Math.round(
            (Math.round((result.audio1Score + result.audio2Score) / 2) * 100) /
              5
          ),
          Math.round(
            (Math.round((result.video1Score + result.video2Score) / 2) * 100) /
              5
          ),
          totalScore,
          result.assesorScore,
          result.assesment == "Test not taken"
            ? "No results"
            : result.assesment,
          result.assesment == "Unmarked" || result.assesment == "Test not taken"
            ? "N/A"
            : totalScore < 50
            ? "Failed"
            : "Passed",
          result.notes,
        ]);
      });
      setTableData(tableRowsData);

      let noReplyNumber = 0;
      let unmarkedNumber = 0;
      let selectedNumber = 0;
      let notSelectedNumber = 0;
      tableRowsData.forEach((row) => {
        if (row[8] == "Test not taken" || row[8] == "No results")
          noReplyNumber += 1;
        if (row[8] == "Unmarked") unmarkedNumber += 1;
        if (row[9] == "Passed") selectedNumber += 1;
        if (row[9] == "Failed") notSelectedNumber += 1;
      });

      setNoReply(noReplyNumber);
      setUnmarked(unmarkedNumber);
      setSelected(selectedNumber);
      setNotSelected(notSelectedNumber);

      let deadlineDate = new Date(
        parseInt(props.location.state.job["endDate"].split("/")[2]),
        parseInt(props.location.state.job["endDate"].split("/")[0]) - 1,
        parseInt(props.location.state.job["endDate"].split("/")[1])
      );
      let todayDate = new Date();
      let deadlineDateUnix = Math.floor(deadlineDate.getTime() / 1000);
      let todayDateUnix = Math.floor(todayDate.getTime() / 1000);
      let daysLeft = deadlineDateUnix - todayDateUnix;
      setDaysUntilDeadline(Math.floor(daysLeft / 86400));

      setLoaded(true);
    }

    getData().then((r) => console.log("Success!"));
  }, [props]);

  let content =
    loaded == false ? (
      <div
        style={{
          height: "95vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader
          type="Plane"
          color="#333366"
          secondaryColor="#333366"
          height={10}
          width={10}
        />
      </div>
    ) : (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters style={{ marginTop: 15 }}>
          <i
            className="material-icons"
            style={{
              fontWeight: 400,
              fontSize: 20,
              color: "#3D5170",
              marginRight: 15,
              paddingTop: 12,
              cursor: "pointer",
            }}
            onClick={() => props.history.goBack()}
          >
            keyboard_backspace
          </i>
          <span
            style={{
              fontSize: 20,
              marginTop: "10px",
              float: "left",
              cursor: "pointer",
            }}
            onClick={() => props.history.goBack()}
          >
            Return to overview page
          </span>
        </Row>
        <Row noGutters className="page-header py-4">
          <PageTitle noGutters sm="4" className="mb-sm-0" title="Job Details" />
        </Row>

        <Row>
          <Col lg="6" key={2}>
            <Card small className="card-post mb-4">
              <CardBody>
                <Row style={{ marginTop: "-10px" }}>
                  <h5 className="card-title" style={{ fontSize: "15px" }}>
                    Name
                  </h5>
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px", float: "right" }}
                    >
                      {props.location.state.job["title"]}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "-15px" }}>
                  <h5 className="card-title" style={{ fontSize: "15px" }}>
                    Description
                  </h5>
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px", float: "right" }}
                    >
                      {props.location.state.job["description"].length <= 20
                        ? props.location.state.job["description"]
                        : props.location.state.job["description"].substring(
                            0,
                            20
                          ) + "..."}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "-15px" }}>
                  <h5 className="card-title" style={{ fontSize: "15px" }}>
                    Close Date
                  </h5>
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px", float: "right" }}
                    >
                      {props.location.state.job["endDate"].split("/")[1] +
                        "/" +
                        props.location.state.job["endDate"].split("/")[0] +
                        "/" +
                        props.location.state.job["endDate"].split("/")[2]}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "-15px", marginBottom: "-30px" }}>
                  <h5 className="card-title" style={{ fontSize: "15px" }}>
                    Candidates invited
                  </h5>
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px", float: "right" }}
                    >
                      {props.location.state.job["candidatesForTest1"].length}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" key={2}>
            <Card small className="card-post mb-4">
              <CardHeader className="border-bottom" style={{ height: "40px" }}>
                <Row style={{ marginTop: "-9px" }}>
                  <Col>
                    <h5 className="card-title" style={{ fontSize: "15px" }}>
                      Invited
                    </h5>
                  </Col>
                  {/*<Col>*/}
                  {/*  <h5 className="card-title" style={{fontSize: '15px'}}>Required</h5>*/}
                  {/*</Col>*/}
                  <Col>
                    <h5 className="card-title" style={{ fontSize: "15px" }}>
                      No reply
                    </h5>
                  </Col>
                  <Col>
                    <h5 className="card-title" style={{ fontSize: "15px" }}>
                      Unmarked
                    </h5>
                  </Col>
                  {/*<Col>*/}
                  {/*  <h5 className="card-title" style={{fontSize: '15px'}}>Selected</h5>*/}
                  {/*</Col>*/}
                  {/*<Col>*/}
                  {/*  <h5 className="card-title" style={{fontSize: '15px'}}>Not selected</h5>*/}
                  {/*</Col>*/}
                </Row>
              </CardHeader>
              <CardBody>
                <Row style={{ marginTop: "-10px", marginBottom: "-30px" }}>
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px" }}
                    >
                      {props.location.state.job["candidatesForTest1"].length}
                    </p>
                  </Col>
                  {/*<Col>*/}
                  {/*  <p className="card-text text-muted" style={{marginTop: '3px'}}>{props.location.state.job['numberOfHires']}</p>*/}
                  {/*</Col>*/}
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px" }}
                    >
                      {noReply}
                    </p>
                  </Col>
                  <Col>
                    <p
                      className="card-text text-muted"
                      style={{ marginTop: "3px" }}
                    >
                      {unmarked}
                    </p>
                  </Col>
                  {/*<Col>*/}
                  {/*  <p className="card-text text-muted" style={{marginTop: '3px'}}>{selected}</p>*/}
                  {/*</Col>*/}
                  {/*<Col>*/}
                  {/*  <p className="card-text text-muted" style={{marginTop: '3px'}}>{notSelected}</p>*/}
                  {/*</Col>*/}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/*<Row>*/}
        {/*  <Col lg="8" key={2}>*/}
        {/*    <Card small className="card-post mb-4">*/}
        {/*      <CardBody>*/}
        {/*        <Row style={{marginTop: '-10px'}}>*/}
        {/*          <h5 className="card-title" style={{fontSize: '15px'}}>Number of candidates that need to pass in order meet requirement </h5>*/}
        {/*          <Col>*/}
        {/*            <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>{props.location.state.job['numberOfHires']}</p>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}
        {/*        <Row style={{marginTop: '-15px', marginBottom: '-30px'}}>*/}
        {/*          <h5 className="card-title" style={{fontSize: '15px'}}>Days before deadline</h5>*/}
        {/*          <Col>*/}
        {/*            <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>{daysUntilDeadline}</p>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row style={{ marginBottom: "50px" }}>
          <Col lg={12}>
            <MUIDataTable
              title={"Candidate results"}
              data={tableData}
              columns={columns}
              options={options}
            />
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container fluid className="main-content-container px-4">
      {content}
    </Container>
  );
}

ClientJobDetail.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array,
};

ClientJobDetail.defaultProps = {
  smallStats: [
    {
      label: "Posts",
      value: "2,390",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7],
        },
      ],
    },
    {
      label: "Pages",
      value: "182",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4],
        },
      ],
    },
    {
      label: "Comments",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3],
        },
      ],
    },
    {
      label: "New Customers",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8],
        },
      ],
    },
    {
      label: "Subscribers",
      value: "17,281",
      percentage: "2.4%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [3, 2, 3, 2, 4, 5, 4],
        },
      ],
    },
  ],
};

const ClientJobDetailOverviewForm = compose(withFirebase)(ClientJobDetail);

export default ClientJobDetailOverviewForm;
