/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { compose } from "recompose";
import { withFirebase } from "../components/Firebase";
import NewJobDetails from "../components/user-profile-lite/NewJobDetails";
import NewJobAssesors from "../components/user-profile-lite/NewJobAssesors";
import Loader from "react-loader-spinner";

function ClientDashboard(props) {
  const [jobs, setJobs] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getData() {
      props.firebase.doGetAirlineJobs().then((res) => {
        setJobs(res);
        setLoaded(true);
      });
    }

    getData().then((r) => console.log("Success!"));
  }, [props]);

  let content =
    loaded == false ? (
      <div
        style={{
          height: "95vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader
          type="Plane"
          color="#333366"
          secondaryColor="#333366"
          height={10}
          width={10}
        />
      </div>
    ) : (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Open Jobs"
            subtitle="Jobs"
            className="text-sm-left"
          />
        </Row>

        <Row>
          {jobs.length == 0 ? (
            <Col lg="4" key={2}>
              <h4>No jobs yet!</h4>
            </Col>
          ) : (
            jobs.map((job, idx) => (
              <Col lg="4" key={2}>
                <Card small className="card-post mb-4">
                  <CardBody>
                    <Row style={{ marginTop: "-10px" }}>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Name
                      </h5>
                      <Col>
                        <p
                          className="card-text text-muted"
                          style={{ marginTop: "3px", float: "right" }}
                        >
                          {job["title"]}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "-15px" }}>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Description
                      </h5>
                      <Col>
                        <p
                          className="card-text text-muted"
                          style={{ marginTop: "3px", float: "right" }}
                        >
                          {job["description"].length <= 20
                            ? job["description"]
                            : job["description"].substring(0, 20) + "..."}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "-15px" }}>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Close Date
                      </h5>
                      <Col>
                        <p
                          className="card-text text-muted"
                          style={{ marginTop: "3px", float: "right" }}
                        >
                          {job["endDate"].split("/")[1] +
                            "/" +
                            job["endDate"].split("/")[0] +
                            "/" +
                            job["endDate"].split("/")[2]}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "-15px", marginBottom: "-30px" }}>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Candidates invited
                      </h5>
                      <Col>
                        <p
                          className="card-text text-muted"
                          style={{ marginTop: "3px", float: "right" }}
                        >
                          {job["candidatesForTest1"].length}
                        </p>
                      </Col>
                    </Row>
                    {/*<Row style={{marginTop: '-15px'}}>*/}
                    {/*    <h5 className="card-title" style={{fontSize: '15px'}}>Assesor</h5>*/}
                    {/*    <Col>*/}
                    {/*        <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>{job['assesorName1']}</p>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row style={{marginTop: '-15px'}}>*/}
                    {/*    <h5 className="card-title" style={{fontSize: '15px'}}>Assesor</h5>*/}
                    {/*    <Col>*/}
                    {/*        <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>{job['assesorName2']}</p>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row style={{marginTop: '-15px', marginBottom: '-30px'}}>*/}
                    {/*    <h5 className="card-title" style={{fontSize: '15px'}}>Assesor</h5>*/}
                    {/*    <Col>*/}
                    {/*        <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>{job['assesorName3']}</p>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </CardBody>
                  <CardFooter className="border-top">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        size="sm"
                        theme="success"
                        style={{ width: "50%" }}
                        onClick={() => {
                          props.history.push({
                            pathname: "/job-detail",
                            state: { job: job },
                          });
                        }}
                      >
                        <i /> Job Details
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>
    );

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {content}
    </Container>
  );
}

const ClientDashboardForm = compose(withFirebase)(ClientDashboard);

export default ClientDashboardForm;
