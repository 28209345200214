import React, {Component, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button as CardButton } from "shards-react";
import { withFirebase } from '../components/Firebase';
import { firebaseApp } from '../components/Firebase/firebase';
import MuiPhoneNumber from "material-ui-phone-number";
import { compose } from 'recompose';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: 'lightgray',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignInPhone(props) {

    const classes = useStyles();

    const [phoneNumber, setPhoneNumber] = useState(null)

    return (
        <Grid id='root' container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={5} md={8} className={classes.image}>
                {
                    window.innerWidth > 800 ? <>
                            <div style={{marginLeft: '50px', marginTop: '30%', position: 'relative'}}>
                                <h3 style={{color: 'black', opacity: 0.7, fontFamily: 'GalanoGrotesqueBold', textAlign: 'center'}}>Image needs to be inserted here</h3>
                                {/*<h3 style={{color: 'black', opacity: 0.7, fontFamily: 'GalanoGrotesqueBold', textAlign: 'center'}}><i>lack of experience myth"</i></h3>*/}
                                {/*<CardButton pill outline size="lg" className="mb-2" theme="dark" style={{marginTop: '20px', position: 'absolute', left: '40%'}} onClick={() => {*/}
                                {/*    // window.location.href = '/landing'*/}
                                {/*}}>*/}
                                {/*    <i className="fas fa-arrow-right" style={{ marginRight:"5px"}}/> More info*/}
                                {/*</CardButton>*/}
                            </div>
                        </> :
                        <></>
                }
            </Grid>
            {/* Add landing in the upper Grid */}
            <Grid item xs={12} sm={9} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {/*<LockOutlinedIcon />*/}
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Phone Verification
                    </Typography>
                    <form className={classes.form} noValidate>
                        <MuiPhoneNumber
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="phone"
                            label="Phone Number"
                            data-cy="user-phone"
                            defaultCountry={"us"}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                        />
                        <div id="recaptcha-container"/>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                console.log(props.location.state.password)
                                let container = document.getElementById('root');
                                props.firebase.doSignInWithPhone(props, props.location.state.email, props.location.state.password, phoneNumber, new firebaseApp.auth.RecaptchaVerifier('recaptcha-container'), container).then(r => console.log(r))
                            }}
                        >
                            Send Verification Code
                        </Button>
                        <Box mt={5}>
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );

}

const SignInPhoneForm = compose(
    withFirebase,
)(SignInPhone);

export default SignInPhoneForm;
