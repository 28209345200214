import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  Alert,
  Progress,
  Slider as Sliding,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import ReactWebMediaPlayer from "react-web-media-player";
import { compose } from "recompose";
import { withFirebase } from "../components/Firebase";

import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";

import Switch from "react-switch";
import Loader from "react-loader-spinner";

function ClientCandidateResultsMediaPlayer(props) {
  const [audio1Pronounciation, setAudio1Pronounciation] = React.useState(
    props.location.state.scores.audio1Pronounciation
  );
  const [audio1Clarity, setAudio1Clarity] = React.useState(
    props.location.state.scores.audio1Clarity
  );
  const [audio1Fluidity, setAudio1Fluidity] = React.useState(
    props.location.state.scores.audio1Fluidity
  );

  const [audio2Pronounciation, setAudio2Pronounciation] = React.useState(
    props.location.state.scores.audio2Pronounciation
  );
  const [audio2Clarity, setAudio2Clarity] = React.useState(
    props.location.state.scores.audio2Clarity
  );
  const [audio2Fluidity, setAudio2Fluidity] = React.useState(
    props.location.state.scores.audio2Fluidity
  );

  const [video1Appearance, setVideo1Appearance] = React.useState(
    props.location.state.scores.video1Appearance
  );
  const [video1Demeanor, setVideo1Demeanor] = React.useState(
    props.location.state.scores.video1Demeanor
  );
  const [video1Verbal, setVideo1Verbal] = React.useState(
    props.location.state.scores.video1Verbal
  );
  const [video1Communication, setVideo1Communication] = React.useState(
    props.location.state.scores.video1Communication
  );
  const [video1Quality, setVideo1Quality] = React.useState(
    props.location.state.scores.video1Quality
  );

  const [video2Appearance, setVideo2Appearance] = React.useState(
    props.location.state.scores.video2Appearance
  );
  const [video2Demeanor, setVideo2Demeanor] = React.useState(
    props.location.state.scores.video2Demeanor
  );
  const [video2Verbal, setVideo2Verbal] = React.useState(
    props.location.state.scores.video2Verbal
  );
  const [video2Communication, setVideo2Communication] = React.useState(
    props.location.state.scores.video2Communication
  );
  const [video2Quality, setVideo2Quality] = React.useState(
    props.location.state.scores.video2Quality
  );

  const [loaded, setLoaded] = useState(true);
  const [firstVideoLoaded, setFirstVideoLoaded] = useState(false);
  const [percentage, setPercentage] = useState(0);

  let isFirstTimeEditing;
  video1Appearance == 0 &&
  video1Demeanor == 0 &&
  video1Verbal == 0 &&
  video1Communication == 0 &&
  video1Quality == 0 &&
  video2Appearance == 0 &&
  video2Demeanor == 0 &&
  video2Verbal == 0 &&
  video2Communication == 0 &&
  video2Quality == 0 &&
  audio1Pronounciation == 0 &&
  audio1Clarity == 0 &&
  audio1Fluidity == 0 &&
  audio2Pronounciation == 0 &&
  audio2Clarity == 0 &&
  audio2Fluidity == 0
    ? (isFirstTimeEditing = true)
    : video1Appearance == 0 &&
      video1Demeanor == 0 &&
      video1Verbal == 0 &&
      video1Communication == 0 &&
      video1Quality == 0 &&
      video2Appearance == 0 &&
      video2Demeanor == 0 &&
      video2Verbal == 0 &&
      video2Communication == 0 &&
      video2Quality == 0 &&
      audio1Pronounciation == 0 &&
      audio1Clarity == 0 &&
      audio1Fluidity == 0
    ? (isFirstTimeEditing = true)
    : (isFirstTimeEditing = false);

  const [canEdit, setCanEdit] = React.useState(isFirstTimeEditing);

  function audio1PronounciationChange(event) {
    setAudio1Pronounciation(parseInt(event.target.value));
  }

  function audio1ClarityChange(event) {
    setAudio1Clarity(parseInt(event.target.value));
  }

  function audio1FluidityChange(event) {
    setAudio1Fluidity(parseInt(event.target.value));
  }

  function audio2PronounciationChange(event) {
    setAudio2Pronounciation(parseInt(event.target.value));
  }

  function audio2ClarityChange(event) {
    setAudio2Clarity(parseInt(event.target.value));
  }

  function audio2FluidityChange(event) {
    setAudio2Fluidity(parseInt(event.target.value));
  }

  function video1AppearanceChange(event) {
    setVideo1Appearance(parseInt(event.target.value));
  }

  function video1DemeanorChange(event) {
    setVideo1Demeanor(parseInt(event.target.value));
  }

  function video1VerbalChange(event) {
    setVideo1Verbal(parseInt(event.target.value));
  }

  function video1CommunicationChange(event) {
    setVideo1Communication(parseInt(event.target.value));
  }

  function video1QualityChange(event) {
    setVideo1Quality(parseInt(event.target.value));
  }

  function video2AppearanceChange(event) {
    setVideo2Appearance(parseInt(event.target.value));
  }

  function video2DemeanorChange(event) {
    setVideo2Demeanor(parseInt(event.target.value));
  }

  function video2VerbalChange(event) {
    setVideo2Verbal(parseInt(event.target.value));
  }

  function video2CommunicationChange(event) {
    setVideo2Communication(parseInt(event.target.value));
  }

  function video2QualityChange(event) {
    setVideo2Quality(parseInt(event.target.value));
  }

  function handleChange() {
    canEdit === true ? setCanEdit(false) : setCanEdit(true);
  }

  useEffect(() => {
    setTimeout(() => {
      setPercentage(100);
      setTimeout(() => {
        setFirstVideoLoaded(true);
      }, 1500);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {loaded == false ? (
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.68)",
            height: "95%",
            width: "85%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            position: "fixed",
          }}
        >
          <Loader
            type="Plane"
            color="#333366"
            secondaryColor="#333366"
            height={10}
            width={10}
          />
        </div>
      ) : (
        <></>
      )}
      <Container
        fluid
        className="main-content-container px-4 pb-4"
        style={{ zIndex: 99 }}
      >
        <Row noGutters style={{ marginTop: 15 }}>
          <i
            className="material-icons"
            style={{
              fontWeight: 400,
              fontSize: 20,
              color: "#3D5170",
              marginRight: 15,
              paddingTop: 12,
              cursor: "pointer",
            }}
            onClick={() => props.history.goBack()}
          >
            keyboard_backspace
          </i>
          <span
            style={{
              fontSize: 20,
              marginTop: "10px",
              float: "left",
              cursor: "pointer",
            }}
            onClick={() => props.history.goBack()}
          >
            Return to candidate details page
          </span>
        </Row>
        <Row noGutters className="page-header py-4">
          <PageTitle
            noGutters
            sm="4"
            className="mb-sm-0"
            title="Audio and video results"
          />
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row className="m-0">
                  <h6 className="m-0" style={{ paddingRight: 20 }}>
                    Edit scores
                  </h6>
                  <Switch
                    onChange={handleChange}
                    checked={canEdit}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={40}
                  />
                </Row>
              </CardHeader>
              <Row >
                <Col
                  sm={7}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginTop: 45 }}>
                    <CardBody small className="mb-1">
                      <ReactWebMediaPlayer
                        title="Candidate's recorded video 1"
                        video={
                          props.location.state.media.video1
                            ? props.location.state.media.video1
                            : null
                        }
                      />
                    </CardBody>
                  </div>
                </Col>
                <Col
                  sm={5}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "30%",
                  }}
                >
                  <Card>
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">Video 1 scoring</h6>
                    </CardHeader>
                    <CardBody className="p-0 pb-3">
                      <table
                        className="table mb-0"
                        style={{ width: window.innerWidth / 3.3 }}
                      >
                        <thead className="bg-light">
                          <tr>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "center" }}
                            ></th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              1
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              2
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              3
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              4
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              5
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b>Appearance</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Appearance === 1}
                                type="radio"
                                value="1"
                                name="appearance1"
                                onChange={video1AppearanceChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Appearance === 2}
                                type="radio"
                                value="2"
                                name="appearance1"
                                onChange={video1AppearanceChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Appearance === 3}
                                type="radio"
                                value="3"
                                name="appearance1"
                                onChange={video1AppearanceChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Appearance === 4}
                                type="radio"
                                value="4"
                                name="appearance1"
                                onChange={video1AppearanceChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Appearance === 5}
                                type="radio"
                                value="5"
                                name="appearance1"
                                onChange={video1AppearanceChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Demeanor</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Demeanor === 1}
                                type="radio"
                                value="1"
                                name="demeanor1"
                                onChange={video1DemeanorChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Demeanor === 2}
                                type="radio"
                                value="2"
                                name="demeanor1"
                                onChange={video1DemeanorChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Demeanor === 3}
                                type="radio"
                                value="3"
                                name="demeanor1"
                                onChange={video1DemeanorChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Demeanor === 4}
                                type="radio"
                                value="4"
                                name="demeanor1"
                                onChange={video1DemeanorChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Demeanor === 5}
                                type="radio"
                                value="5"
                                name="demeanor1"
                                onChange={video1DemeanorChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Verbal Expression</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Verbal === 1}
                                type="radio"
                                value="1"
                                name="verbal1"
                                onChange={video1VerbalChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Verbal === 2}
                                type="radio"
                                value="2"
                                name="verbal1"
                                onChange={video1VerbalChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Verbal === 3}
                                type="radio"
                                value="3"
                                name="verbal1"
                                onChange={video1VerbalChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Verbal === 4}
                                type="radio"
                                value="4"
                                name="verbal1"
                                onChange={video1VerbalChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Verbal === 5}
                                type="radio"
                                value="5"
                                name="verbal1"
                                onChange={video1VerbalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Communication of beliefs</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Communication === 1}
                                type="radio"
                                value="1"
                                name="communication1"
                                onChange={video1CommunicationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Communication === 2}
                                type="radio"
                                value="2"
                                name="communication1"
                                onChange={video1CommunicationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Communication === 3}
                                type="radio"
                                value="3"
                                name="communication1"
                                onChange={video1CommunicationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Communication === 4}
                                type="radio"
                                value="4"
                                name="communication1"
                                onChange={video1CommunicationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Communication === 5}
                                type="radio"
                                value="5"
                                name="communication1"
                                onChange={video1CommunicationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Image quality</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Quality === 1}
                                type="radio"
                                value="1"
                                name="quality1"
                                onChange={video1QualityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Quality === 2}
                                type="radio"
                                value="2"
                                name="quality1"
                                onChange={video1QualityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Quality === 3}
                                type="radio"
                                value="3"
                                name="quality1"
                                onChange={video1QualityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Quality === 4}
                                type="radio"
                                value="4"
                                name="quality1"
                                onChange={video1QualityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={video1Quality === 5}
                                type="radio"
                                value="5"
                                name="quality1"
                                onChange={video1QualityChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {props.location.state.media.video2 !== null ? (
                <Row>
                  <Col
                    sm={7}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginTop: 45 }}>
                      <CardBody small className="mb-1">
                        {firstVideoLoaded ? (
                          <ReactWebMediaPlayer
                            title="Candidate's recorded video 2"
                            video={
                              props.location.state.media.video2
                                ? props.location.state.media.video2
                                : null
                            }
                          />
                        ) : (
                          <Progress
                            theme="primary"
                            value={percentage}
                            style={{ width: "75%", marginTop: 50 }}
                          />
                        )}
                      </CardBody>
                    </div>
                  </Col>
                  <Col
                    sm={5}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <Card>
                      <CardHeader className="border-bottom">
                        <h6 className="m-0">Video 2 scoring</h6>
                      </CardHeader>
                      <CardBody className="p-0 pb-3">
                        <table
                          className="table mb-0"
                          style={{ width: window.innerWidth / 3.3 }}
                        >
                          <thead className="bg-light">
                            <tr>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "center" }}
                              ></th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                1
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                2
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                3
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                4
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                5
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <b>Appearance</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Appearance === 1}
                                  type="radio"
                                  value="1"
                                  name="appearance2"
                                  onChange={video2AppearanceChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Appearance === 2}
                                  type="radio"
                                  value="2"
                                  name="appearance2"
                                  onChange={video2AppearanceChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Appearance === 3}
                                  type="radio"
                                  value="3"
                                  name="appearance2"
                                  onChange={video2AppearanceChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Appearance === 4}
                                  type="radio"
                                  value="4"
                                  name="appearance2"
                                  onChange={video2AppearanceChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Appearance === 5}
                                  type="radio"
                                  value="5"
                                  name="appearance2"
                                  onChange={video2AppearanceChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Demeanor</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Demeanor === 1}
                                  type="radio"
                                  value="1"
                                  name="demeanor2"
                                  onChange={video2DemeanorChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Demeanor === 2}
                                  type="radio"
                                  value="2"
                                  name="demeanor2"
                                  onChange={video2DemeanorChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Demeanor === 3}
                                  type="radio"
                                  value="3"
                                  name="demeanor2"
                                  onChange={video2DemeanorChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Demeanor === 4}
                                  type="radio"
                                  value="4"
                                  name="demeanor2"
                                  onChange={video2DemeanorChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Demeanor === 5}
                                  type="radio"
                                  value="5"
                                  name="demeanor2"
                                  onChange={video2DemeanorChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Verbal Expression</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Verbal === 1}
                                  type="radio"
                                  value="1"
                                  name="verbal2"
                                  onChange={video2VerbalChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Verbal === 2}
                                  type="radio"
                                  value="2"
                                  name="verbal2"
                                  onChange={video2VerbalChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Verbal === 3}
                                  type="radio"
                                  value="3"
                                  name="verbal2"
                                  onChange={video2VerbalChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Verbal === 4}
                                  type="radio"
                                  value="4"
                                  name="verbal2"
                                  onChange={video2VerbalChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Verbal === 5}
                                  type="radio"
                                  value="5"
                                  name="verbal2"
                                  onChange={video2VerbalChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Communication of beliefs</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Communication === 1}
                                  type="radio"
                                  value="1"
                                  name="communication2"
                                  onChange={video2CommunicationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Communication === 2}
                                  type="radio"
                                  value="2"
                                  name="communication2"
                                  onChange={video2CommunicationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Communication === 3}
                                  type="radio"
                                  value="3"
                                  name="communication2"
                                  onChange={video2CommunicationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Communication === 4}
                                  type="radio"
                                  value="4"
                                  name="communication2"
                                  onChange={video2CommunicationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Communication === 5}
                                  type="radio"
                                  value="5"
                                  name="communication2"
                                  onChange={video2CommunicationChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Image quality</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Quality === 1}
                                  type="radio"
                                  value="1"
                                  name="quality2"
                                  onChange={video2QualityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Quality === 2}
                                  type="radio"
                                  value="2"
                                  name="quality2"
                                  onChange={video2QualityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Quality === 3}
                                  type="radio"
                                  value="3"
                                  name="quality2"
                                  onChange={video2QualityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Quality === 4}
                                  type="radio"
                                  value="4"
                                  name="quality2"
                                  onChange={video2QualityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={video2Quality === 5}
                                  type="radio"
                                  value="5"
                                  name="quality2"
                                  onChange={video2QualityChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row>
                {/*<p>{candidates[currentCandidateIndex - 1].candidateUsername}</p>*/}
                <Col>
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">Video recordings</h6>
                    </CardHeader>
                    <CardBody style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  marginTop: -15,
                                  backgroundColor: "#FBFBFB",
                                  display: "flex",
                                  justifyContent: "space-around",
                                  flexDirection: "row",
                                  width: "100%",
                                  height: 50,
                                }}
                              >
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Not competent
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Marginal skills
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Adequate skills
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Good skills
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Superior skills
                                  </p>
                                </div>
                              </div>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={
                                  props.location.state.media.video2 !== null
                                    ? [
                                        (100 *
                                          Math.round(
                                            ((video1Appearance +
                                              video1Demeanor +
                                              video1Verbal +
                                              video1Communication +
                                              video1Quality) /
                                              5 +
                                              (video2Appearance +
                                                video2Demeanor +
                                                video2Verbal +
                                                video2Communication +
                                                video2Quality) /
                                                5) /
                                              2
                                          )) /
                                          5,
                                      ]
                                    : [
                                        (100 *
                                          Math.round(
                                            (video1Appearance +
                                              video1Demeanor +
                                              video1Verbal +
                                              video1Communication +
                                              video1Quality) /
                                              5
                                          )) /
                                          5,
                                      ]
                                }
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginBottom: 30, marginTop: 30 }}>
                <Col
                  sm={7}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/*<CardBody small className="mb-4">*/}
                  <ReactPlayer
                    url={props.location.state.media.audio1}
                    controls={true}
                    height={80}
                    width={500}
                  />
                </Col>
                <Col
                  sm={5}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "30%",
                  }}
                >
                  <Card>
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">Audio 1 scoring</h6>
                    </CardHeader>
                    <CardBody className="p-0 pb-3">
                      <table
                        className="table mb-0"
                        style={{ width: window.innerWidth / 3.3 }}
                      >
                        <thead className="bg-light">
                          <tr>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "center" }}
                            ></th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              1
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              2
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              3
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              4
                            </th>
                            <th
                              scope="col"
                              className="border-0"
                              style={{ textAlign: "left" }}
                            >
                              5
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b>Pronounciation</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Pronounciation === 1}
                                type="radio"
                                value="1"
                                name="pronounciation1"
                                onChange={audio1PronounciationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Pronounciation === 2}
                                type="radio"
                                value="2"
                                name="pronounciation1"
                                onChange={audio1PronounciationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Pronounciation === 3}
                                type="radio"
                                value="3"
                                name="pronounciation1"
                                onChange={audio1PronounciationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Pronounciation === 4}
                                type="radio"
                                value="4"
                                name="pronounciation1"
                                onChange={audio1PronounciationChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Pronounciation === 5}
                                type="radio"
                                value="5"
                                name="pronounciation1"
                                onChange={audio1PronounciationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Clarity</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Clarity === 1}
                                type="radio"
                                value="1"
                                name="clarity1"
                                onChange={audio1ClarityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Clarity === 2}
                                type="radio"
                                value="2"
                                name="clarity1"
                                onChange={audio1ClarityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Clarity === 3}
                                type="radio"
                                value="3"
                                name="clarity1"
                                onChange={audio1ClarityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Clarity === 4}
                                type="radio"
                                value="4"
                                name="clarity1"
                                onChange={audio1ClarityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Clarity === 5}
                                type="radio"
                                value="5"
                                name="clarity1"
                                onChange={audio1ClarityChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Fluidity</b>
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Fluidity === 1}
                                type="radio"
                                value="1"
                                name="fluidity1"
                                onChange={audio1FluidityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Fluidity === 2}
                                type="radio"
                                value="2"
                                name="fluidity1"
                                onChange={audio1FluidityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Fluidity === 3}
                                type="radio"
                                value="3"
                                name="fluidity1"
                                onChange={audio1FluidityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Fluidity === 4}
                                type="radio"
                                value="4"
                                name="fluidity1"
                                onChange={audio1FluidityChange}
                              />
                            </td>
                            <td>
                              <input
                                disabled={!canEdit}
                                defaultChecked={audio1Fluidity === 5}
                                type="radio"
                                value="5"
                                name="fluidity1"
                                onChange={audio1FluidityChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {props.location.state.media.audio2 !== null ? (
                <Row style={{ marginBottom: 30 }}>
                  <Col
                    sm={7}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/*<CardBody small className="mb-4">*/}
                    <ReactPlayer
                      url={props.location.state.media.audio2}
                      controls={true}
                      height={80}
                      width={500}
                    />
                    {/*</CardBody>*/}
                  </Col>
                  <Col
                    sm={5}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <Card>
                      <CardHeader className="border-bottom">
                        <h6 className="m-0">Audio 2 scoring</h6>
                      </CardHeader>
                      <CardBody className="p-0 pb-3">
                        <table
                          className="table mb-0"
                          style={{ width: window.innerWidth / 3.3 }}
                        >
                          <thead className="bg-light">
                            <tr>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "center" }}
                              ></th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                1
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                2
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                3
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                4
                              </th>
                              <th
                                scope="col"
                                className="border-0"
                                style={{ textAlign: "left" }}
                              >
                                5
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <b>Pronounciation</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Pronounciation === 1}
                                  type="radio"
                                  value="1"
                                  name="pronounciation2"
                                  onChange={audio2PronounciationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Pronounciation === 2}
                                  type="radio"
                                  value="2"
                                  name="pronounciation2"
                                  onChange={audio2PronounciationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Pronounciation === 3}
                                  type="radio"
                                  value="3"
                                  name="pronounciation2"
                                  onChange={audio2PronounciationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Pronounciation === 4}
                                  type="radio"
                                  value="4"
                                  name="pronounciation2"
                                  onChange={audio2PronounciationChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Pronounciation === 5}
                                  type="radio"
                                  value="5"
                                  name="pronounciation2"
                                  onChange={audio2PronounciationChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Clarity</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Clarity === 1}
                                  type="radio"
                                  value="1"
                                  name="clarity2"
                                  onChange={audio2ClarityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Clarity === 2}
                                  type="radio"
                                  value="2"
                                  name="clarity2"
                                  onChange={audio2ClarityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Clarity === 3}
                                  type="radio"
                                  value="3"
                                  name="clarity2"
                                  onChange={audio2ClarityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Clarity === 4}
                                  type="radio"
                                  value="4"
                                  name="clarity2"
                                  onChange={audio2ClarityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Clarity === 5}
                                  type="radio"
                                  value="5"
                                  name="clarity2"
                                  onChange={audio2ClarityChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Fluidity</b>
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Fluidity === 1}
                                  type="radio"
                                  value="1"
                                  name="fluidity2"
                                  onChange={audio2FluidityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Fluidity === 2}
                                  type="radio"
                                  value="2"
                                  name="fluidity2"
                                  onChange={audio2FluidityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Fluidity === 3}
                                  type="radio"
                                  value="3"
                                  name="fluidity2"
                                  onChange={audio2FluidityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Fluidity === 4}
                                  type="radio"
                                  value="4"
                                  name="fluidity2"
                                  onChange={audio2FluidityChange}
                                />
                              </td>
                              <td>
                                <input
                                  disabled={!canEdit}
                                  defaultChecked={audio2Fluidity === 5}
                                  type="radio"
                                  value="5"
                                  name="fluidity2"
                                  onChange={audio2FluidityChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row>
                {/*<p>{candidates[currentCandidateIndex - 1].candidateUsername}</p>*/}
                <Col>
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">Audio recordings</h6>
                    </CardHeader>
                    <CardBody style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  marginTop: -15,
                                  backgroundColor: "#FBFBFB",
                                  display: "flex",
                                  justifyContent: "space-around",
                                  flexDirection: "row",
                                  width: "100%",
                                  height: 50,
                                }}
                              >
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Not competent
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Marginal skills
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Adequate skills
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Good skills
                                  </p>
                                </div>
                                <div
                                  className="border-0"
                                  style={{
                                    height: "30px",
                                    color: "black",
                                    paddingTop: 15,
                                  }}
                                >
                                  <p style={{ fontWeight: "normal" }}>
                                    Superior skills
                                  </p>
                                </div>
                              </div>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={
                                  props.location.state.media.audio2 !== null
                                    ? [
                                        (100 *
                                          Math.round(
                                            ((audio1Pronounciation +
                                              audio1Clarity +
                                              audio1Fluidity) /
                                              3 +
                                              (audio2Pronounciation +
                                                audio2Clarity +
                                                audio2Fluidity) /
                                                3) /
                                              2
                                          )) /
                                          5,
                                      ]
                                    : [
                                        (100 *
                                          Math.round(
                                            (audio1Pronounciation +
                                              audio1Clarity +
                                              audio1Fluidity) /
                                              3
                                          )) /
                                          5,
                                      ]
                                }
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col
                  md={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    theme="danger"
                    style={{ marginRight: "5%", marginBottom: "5%" }}
                    size="md"
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    disabled={!canEdit}
                    theme="success"
                    size="md"
                    style={{ marginLeft: "5%", marginBottom: "5%" }}
                    onClick={() => {
                      let video1 =
                        (video1Appearance +
                          video1Demeanor +
                          video1Verbal +
                          video1Communication +
                          video1Quality) /
                        5;
                      let video2 =
                        (video2Appearance +
                          video2Demeanor +
                          video2Verbal +
                          video2Communication +
                          video2Quality) /
                        5;
                      let audio1 =
                        (audio1Pronounciation +
                          audio1Clarity +
                          audio1Fluidity) /
                        3;
                      let audio2 =
                        props.location.state.media.audio2 !== null
                          ? (audio2Pronounciation +
                              audio2Clarity +
                              audio2Fluidity) /
                            3
                          : audio1;
                      let scores = {
                        video1Appearance: video1Appearance,
                        video1Demeanor: video1Demeanor,
                        video1Verbal: video1Verbal,
                        video1Communication: video1Communication,
                        video1Quality: video1Quality,
                        video2Appearance: video2Appearance,
                        video2Demeanor: video2Demeanor,
                        video2Verbal: video2Verbal,
                        video2Communication: video2Communication,
                        video2Quality: video2Quality,
                        audio1Pronounciation: audio1Pronounciation,
                        audio1Clarity: audio1Clarity,
                        audio1Fluidity: audio1Fluidity,
                        audio2Pronounciation:
                          props.location.state.media.audio2 !== null
                            ? audio2Pronounciation
                            : audio1Pronounciation,
                        audio2Clarity:
                          props.location.state.media.audio2 !== null
                            ? audio2Clarity
                            : audio1Clarity,
                        audio2Fluidity:
                          props.location.state.media.audio2 !== null
                            ? audio2Fluidity
                            : audio1Fluidity,
                      };
                      let media = {
                        video1Score: video1,
                        video2Score: video2,
                        audio1Score: audio1,
                        audio2Score: audio2,
                      };

                      if (props.location.state.media.audio2 !== null) {
                        if (
                          video1Appearance == 0 ||
                          video1Demeanor == 0 ||
                          video1Verbal == 0 ||
                          video1Communication == 0 ||
                          video1Quality == 0 ||
                          video2Appearance == 0 ||
                          video2Demeanor == 0 ||
                          video2Verbal == 0 ||
                          video2Communication == 0 ||
                          video2Quality == 0 ||
                          audio1Pronounciation == 0 ||
                          audio1Clarity == 0 ||
                          audio1Fluidity == 0 ||
                          audio2Pronounciation == 0 ||
                          audio2Clarity == 0 ||
                          audio2Fluidity == 0
                        ) {
                          alert(
                            "Error: please complete all scores to continue."
                          );
                        } else {
                          setLoaded(false);
                          video1Appearance == 0 &&
                          video1Demeanor == 0 &&
                          video1Verbal == 0 &&
                          video1Communication == 0 &&
                          video1Quality == 0 &&
                          video2Appearance == 0 &&
                          video2Demeanor == 0 &&
                          video2Verbal == 0 &&
                          video2Communication == 0 &&
                          video2Quality == 0 &&
                          audio1Pronounciation == 0 &&
                          audio1Clarity == 0 &&
                          audio1Fluidity == 0 &&
                          audio2Pronounciation == 0 &&
                          audio2Clarity == 0 &&
                          audio2Fluidity == 0
                            ? props.firebase
                                .doAddScoringToTest(
                                  props,
                                  props.location.state.media.uid,
                                  media,
                                  scores,
                                  "Unmarked"
                                )
                                .then((res) => {
                                  setLoaded(true);
                                  let candidates =
                                    props.location.state.candidates;
                                  candidates.forEach((candidate, index) => {
                                    if (
                                      props.location.state.media.uid ===
                                      candidate["uid"]
                                    ) {
                                      candidate["audio1Score"] =
                                        media.audio1Score;
                                      candidate["audio2Score"] =
                                        media.audio2Score;
                                      candidate["video1Score"] =
                                        media.video1Score;
                                      candidate["video2Score"] =
                                        media.video2Score;
                                      candidate["scores"] = scores;

                                      typeof candidate["audio3Score"] ==
                                      "undefined"
                                        ? (candidate["audio3Score"] =
                                            media.audio3Score)
                                        : console.log(
                                            "Audio 3 score does not exist in order to be saved"
                                          );
                                      typeof candidate["video3Score"] ==
                                      "undefined"
                                        ? (candidate["video3Score"] =
                                            media.video3Score)
                                        : console.log(
                                            "Video 3 score does not exist in order to be saved"
                                          );
                                    }
                                  });
                                  props.history.push({
                                    pathname: "/candidate-detail",
                                    state: {
                                      candidates: candidates,
                                      jobData: props.location.state.jobData,
                                      isAlertBoxShown: true,
                                    },
                                  });
                                })
                            : video1Appearance == 0 ||
                              video1Demeanor == 0 ||
                              video1Verbal == 0 ||
                              video1Communication == 0 ||
                              video1Quality == 0 ||
                              video2Appearance == 0 ||
                              video2Demeanor == 0 ||
                              video2Verbal == 0 ||
                              video2Communication == 0 ||
                              video2Quality == 0 ||
                              audio1Pronounciation == 0 ||
                              audio1Clarity == 0 ||
                              audio1Fluidity == 0 ||
                              audio2Pronounciation === 0 ||
                              audio2Clarity === 0 ||
                              audio2Fluidity === 0
                            ? props.firebase
                                .doAddScoringToTest(
                                  props,
                                  props.location.state.media.uid,
                                  media,
                                  scores,
                                  "Incomplete"
                                )
                                .then((res) => {
                                  setLoaded(true);
                                  let candidates =
                                    props.location.state.candidates;
                                  candidates.forEach((candidate, index) => {
                                    if (
                                      props.location.state.media.uid ===
                                      candidate["uid"]
                                    ) {
                                      candidate["audio1Score"] =
                                        media.audio1Score;
                                      candidate["audio2Score"] =
                                        media.audio2Score;
                                      candidate["video1Score"] =
                                        media.video1Score;
                                      candidate["video2Score"] =
                                        media.video2Score;
                                      candidate["scores"] = scores;

                                      typeof candidate["audio3Score"] ==
                                      "undefined"
                                        ? (candidate["audio3Score"] =
                                            media.audio3Score)
                                        : console.log(
                                            "Audio 3 score does not exist in order to be saved"
                                          );
                                      typeof candidate["video3Score"] ==
                                      "undefined"
                                        ? (candidate["video3Score"] =
                                            media.video3Score)
                                        : console.log(
                                            "Video 3 score does not exist in order to be saved"
                                          );
                                    }
                                  });
                                  props.history.push({
                                    pathname: "/candidate-detail",
                                    state: {
                                      candidates: candidates,
                                      jobData: props.location.state.jobData,
                                      isAlertBoxShown: true,
                                    },
                                  });
                                })
                            : video1Appearance !== 0 &&
                              video1Demeanor !== 0 &&
                              video1Verbal !== 0 &&
                              video1Communication !== 0 &&
                              video1Quality !== 0 &&
                              video2Appearance !== 0 &&
                              video2Demeanor !== 0 &&
                              video2Verbal !== 0 &&
                              video2Communication !== 0 &&
                              video2Quality !== 0 &&
                              audio1Pronounciation !== 0 &&
                              audio1Clarity !== 0 &&
                              audio1Fluidity !== 0 &&
                              audio2Pronounciation !== 0 &&
                              audio2Clarity !== 0 &&
                              audio2Fluidity !== 0
                            ? props.firebase
                                .doAddScoringToTest(
                                  props,
                                  props.location.state.media.uid,
                                  media,
                                  scores,
                                  "Marked"
                                )
                                .then((res) => {
                                  setLoaded(true);
                                  let candidates =
                                    props.location.state.candidates;
                                  candidates.forEach((candidate, index) => {
                                    if (
                                      props.location.state.media.uid ===
                                      candidate["uid"]
                                    ) {
                                      candidate["audio1Score"] =
                                        media.audio1Score;
                                      candidate["audio2Score"] =
                                        media.audio2Score;
                                      candidate["video1Score"] =
                                        media.video1Score;
                                      candidate["video2Score"] =
                                        media.video2Score;
                                      candidate["scores"] = scores;

                                      typeof candidate["audio3Score"] ==
                                      "undefined"
                                        ? (candidate["audio3Score"] =
                                            media.audio3Score)
                                        : console.log(
                                            "Audio 3 score does not exist in order to be saved"
                                          );
                                      typeof candidate["video3Score"] ==
                                      "undefined"
                                        ? (candidate["video3Score"] =
                                            media.video3Score)
                                        : console.log(
                                            "Video 3 score does not exist in order to be saved"
                                          );
                                    }
                                  });
                                  props.history.push({
                                    pathname: "/candidate-detail",
                                    state: {
                                      candidates: candidates,
                                      jobData: props.location.state.jobData,
                                      isAlertBoxShown: true,
                                    },
                                  });
                                })
                            : console.log("No other solution!");
                        }
                      } else {
                        if (
                          video1Appearance == 0 ||
                          video1Demeanor == 0 ||
                          video1Verbal == 0 ||
                          video1Communication == 0 ||
                          video1Quality == 0 ||
                          video2Appearance == 0 ||
                          video2Demeanor == 0 ||
                          video2Verbal == 0 ||
                          video2Communication == 0 ||
                          video2Quality == 0 ||
                          audio1Pronounciation == 0 ||
                          audio1Clarity == 0 ||
                          audio1Fluidity == 0
                        ) {
                          alert(
                            "Error: please complete all scores to continue."
                          );
                        } else {
                          video1Appearance == 0 &&
                          video1Demeanor == 0 &&
                          video1Verbal == 0 &&
                          video1Communication == 0 &&
                          video1Quality == 0 &&
                          video2Appearance == 0 &&
                          video2Demeanor == 0 &&
                          video2Verbal == 0 &&
                          video2Communication == 0 &&
                          video2Quality == 0 &&
                          audio1Pronounciation == 0 &&
                          audio1Clarity == 0 &&
                          audio1Fluidity == 0
                            ? props.firebase
                                .doAddScoringToTest(
                                  props,
                                  props.location.state.media.uid,
                                  media,
                                  scores,
                                  "Unmarked"
                                )
                                .then((res) => {
                                  setLoaded(true);
                                  let candidates =
                                    props.location.state.candidates;
                                  candidates.forEach((candidate, index) => {
                                    if (
                                      props.location.state.media.uid ===
                                      candidate["uid"]
                                    ) {
                                      candidate["audio1Score"] =
                                        media.audio1Score;
                                      candidate["audio2Score"] =
                                        media.audio2Score;
                                      candidate["video1Score"] =
                                        media.video1Score;
                                      candidate["video2Score"] =
                                        media.video2Score;
                                      candidate["scores"] = scores;

                                      typeof candidate["audio3Score"] ==
                                      "undefined"
                                        ? (candidate["audio3Score"] =
                                            media.audio3Score)
                                        : console.log(
                                            "Audio 3 score does not exist in order to be saved"
                                          );
                                      typeof candidate["video3Score"] ==
                                      "undefined"
                                        ? (candidate["video3Score"] =
                                            media.video3Score)
                                        : console.log(
                                            "Video 3 score does not exist in order to be saved"
                                          );
                                    }
                                  });
                                  props.history.push({
                                    pathname: "/candidate-detail",
                                    state: {
                                      candidates: candidates,
                                      jobData: props.location.state.jobData,
                                      isAlertBoxShown: true,
                                    },
                                  });
                                })
                            : video1Appearance == 0 ||
                              video1Demeanor == 0 ||
                              video1Verbal == 0 ||
                              video1Communication == 0 ||
                              video1Quality == 0 ||
                              video2Appearance == 0 ||
                              video2Demeanor == 0 ||
                              video2Verbal == 0 ||
                              video2Communication == 0 ||
                              video2Quality == 0 ||
                              audio1Pronounciation == 0 ||
                              audio1Clarity == 0 ||
                              audio1Fluidity == 0
                            ? props.firebase
                                .doAddScoringToTest(
                                  props,
                                  props.location.state.media.uid,
                                  media,
                                  scores,
                                  "Incomplete"
                                )
                                .then((res) => {
                                  setLoaded(true);
                                  let candidates =
                                    props.location.state.candidates;
                                  candidates.forEach((candidate, index) => {
                                    if (
                                      props.location.state.media.uid ===
                                      candidate["uid"]
                                    ) {
                                      candidate["audio1Score"] =
                                        media.audio1Score;
                                      candidate["audio2Score"] =
                                        media.audio2Score;
                                      candidate["video1Score"] =
                                        media.video1Score;
                                      candidate["video2Score"] =
                                        media.video2Score;
                                      candidate["scores"] = scores;

                                      typeof candidate["audio3Score"] ==
                                      "undefined"
                                        ? (candidate["audio3Score"] =
                                            media.audio3Score)
                                        : console.log(
                                            "Audio 3 score does not exist in order to be saved"
                                          );
                                      typeof candidate["video3Score"] ==
                                      "undefined"
                                        ? (candidate["video3Score"] =
                                            media.video3Score)
                                        : console.log(
                                            "Video 3 score does not exist in order to be saved"
                                          );
                                    }
                                  });
                                  props.history.push({
                                    pathname: "/candidate-detail",
                                    state: {
                                      candidates: candidates,
                                      jobData: props.location.state.jobData,
                                      isAlertBoxShown: true,
                                    },
                                  });
                                })
                            : video1Appearance !== 0 &&
                              video1Demeanor !== 0 &&
                              video1Verbal !== 0 &&
                              video1Communication !== 0 &&
                              video1Quality !== 0 &&
                              video2Appearance !== 0 &&
                              video2Demeanor !== 0 &&
                              video2Verbal !== 0 &&
                              video2Communication !== 0 &&
                              video2Quality !== 0 &&
                              audio1Pronounciation !== 0 &&
                              audio1Clarity !== 0 &&
                              audio1Fluidity !== 0
                            ? props.firebase
                                .doAddScoringToTest(
                                  props,
                                  props.location.state.media.uid,
                                  media,
                                  scores,
                                  "Marked"
                                )
                                .then((res) => {
                                  setLoaded(true);
                                  let candidates =
                                    props.location.state.candidates;
                                  candidates.forEach((candidate, index) => {
                                    if (
                                      props.location.state.media.uid ===
                                      candidate["uid"]
                                    ) {
                                      candidate["audio1Score"] =
                                        media.audio1Score;
                                      candidate["audio2Score"] =
                                        media.audio2Score;
                                      candidate["video1Score"] =
                                        media.video1Score;
                                      candidate["video2Score"] =
                                        media.video2Score;
                                      candidate["scores"] = scores;

                                      typeof candidate["audio3Score"] ==
                                      "undefined"
                                        ? (candidate["audio3Score"] =
                                            media.audio3Score)
                                        : console.log(
                                            "Audio 3 score does not exist in order to be saved"
                                          );
                                      typeof candidate["video3Score"] ==
                                      "undefined"
                                        ? (candidate["video3Score"] =
                                            media.video3Score)
                                        : console.log(
                                            "Video 3 score does not exist in order to be saved"
                                          );
                                    }
                                  });
                                  props.history.push({
                                    pathname: "/candidate-detail",
                                    state: {
                                      candidates: candidates,
                                      jobData: props.location.state.jobData,
                                      isAlertBoxShown: true,
                                    },
                                  });
                                })
                            : console.log("No other solution!");
                        }
                      }
                    }}
                  >
                    Save scores
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const ClientCandidateResultsMediaPlayerForm = compose(withFirebase)(
  ClientCandidateResultsMediaPlayer
);

export default ClientCandidateResultsMediaPlayerForm;
