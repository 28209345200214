import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";
import {compose} from "recompose";
import {withFirebase} from "../../Firebase";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      airline: {}
    }

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  componentDidMount() {
    this.props.firebase.doGetCurrentAirline()
        .then((res) => this.setState({airline: res}))
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    const { hideLogoText } = this.props;
    return (
        <div className="main-navbar">
          <Navbar
              className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
              type="light"
          >
            <NavbarBrand
                className="w-100 mr-0"
                href="#"
                style={{ lineHeight: "25px" }}
            >
              <div className="d-table m-auto" style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                {
                  typeof this.state.airline.logo == 'undefined' ?
                      <></>
                      :
                      <img
                          id="main-logo"
                          className="col-md-6 col-md-offset-3 "
                          style={{marginLeft: 15}}
                          src={this.state.airline.logo}
                          alt="screen.hr"
                          onClick={() => {
                            window.location.href = '/dashboard'
                          }}
                      />
                }
                {!hideLogoText && (
                    <span className="d-none d-md-inline ml-1">
                  {/*{this.state.airline.name}*/}
                </span>
                )}
              </div>
            </NavbarBrand>
            {/* eslint-disable-next-line */}
            <a
                className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                onClick={this.handleToggleSidebar}
            >
              <i className="material-icons">&#xE5C4;</i>
            </a>
          </Navbar>
        </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

const SidebarMainNavbarForm = compose(
    withFirebase,
)(SidebarMainNavbar);

export default SidebarMainNavbarForm;
