import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    CardFooter,
    Form,
    FormSelect,
    FormInput, ListGroup, ListGroupItem
} from "shards-react";
import MUIDataTable from "mui-datatables";
import {compose} from "recompose";
import {withFirebase} from "../components/Firebase";
import PageTitle from "../components/common/PageTitle";
import Loader from "react-loader-spinner";
import CustomToolbar from "../components/common/CustomToolbar";
import RangeDatePicker from "../components/common/RangeDatePicker";

function ClientCreditOverview(props) {

    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [spentTableData, setSpentTableData] = useState([])
    const [totalSpentCredits, setTotalSpentCredits] = useState(0)
    const [remainedCredits, setRemainedCredits] = useState(0)

    const [selectedRows, setSelectedRows] = useState([])

    const [airlines, setAirlines] = useState([]);
    const [airline, setAirline] = useState('');
    const [creditNo, setCreditNo] = useState('');
    const [note, setNote] = useState('');

    const columns = ["Date", "No. of credits", "Invoice no./note"];
    const columns2 = ["Date", "Test name", "Candidate", "Credit cost"];
    const options = {
        filterType: 'checkbox',
        downloadOptions: {filterOptions: {useDisplayedRowsOnly: true}},
        draggableColumns: {enabled: true},
        print: false,
        rowsPerPageOptions: [10, 25, 50, 100],
        setRowProps: row => {
            if (row[0]) {
                return {
                    style: { cursor: 'pointer' }
                };
            }
        },
        onRowsSelect: (rowsSelected, allRows) => {
            const selected = allRows.map(item => item.index);
            setSelectedRows(selected);
        },
        responsive: "scroll",
        fixedHeader: true,
    };

    useEffect(() => {

        async function getData() {

            let airlines = await props.firebase.doGetCurrentAirline()
            setRemainedCredits(airlines['creditsLeft'])
            let credits = await props.firebase.doGetMyCreditsPurchases()
            let creditResults = []
            credits.forEach((purchase) => {
                creditResults.push({
                    creditsNumber: (purchase.creditsNumber).toString(),
                    date: purchase.date,
                    note: purchase.note,
                })
            })
            let tableRowsData = []
            creditResults.forEach(result => {
                tableRowsData.push([result.date, result.creditsNumber, result.note])
            })
            setTableData(tableRowsData)

            let spentCreditss = await props.firebase.doGetMySpentCreditsNumber()

            await props.firebase.doGetMySpentCredits(spentCreditss.number, spentCreditss.infos).then((spent) => {
                let spentResults = []
                spent.forEach((credit) => {
                    spentResults.push({
                        date: credit['candidateData'].date,
                        testName: credit['candidateData'].testName,
                        candidateUsername: credit['candidateData'].candidateUsername,
                        creditCost: credit['creditCost']
                    })
                })
                let spentTableRowsData = []
                spentResults.forEach(result => {
                    spentTableRowsData.push([result.date, result.testName, result.candidateUsername, result.creditCost])
                })
                setSpentTableData(spentTableRowsData)
                let spentCredits = 0
                spentTableRowsData.forEach(row => {
                    spentCredits = spentCredits + row[3]
                })
                setTotalSpentCredits(spentCredits)
                setLoaded(true)
            })
        }

        getData().then(r => console.log('Success!'));

    }, [props]);

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Credit Overview" subtitle="Credit" className="text-sm-left" />
            </Row>

            <Row>
                <Col lg="4">
                    <Card small className="card-post mb-4">
                        <CardBody>
                            <Row style={{marginTop: '-10px'}}>
                                <h5 className="card-title" style={{fontSize: '15px'}}>Available credits</h5>
                                <Col>
                                    <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>{remainedCredits - totalSpentCredits}</p>
                                </Col>
                            </Row>
                            {/*<Row style={{marginTop: '-15px', marginBottom: '-30px'}}>*/}
                            {/*    <h5 className="card-title" style={{fontSize: '15px'}}>Required credits</h5>*/}
                            {/*    <Col>*/}
                            {/*        <p className="card-text text-muted" style={{marginTop: '3px', float: 'right'}}>120</p>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row style={{marginBottom: '50px'}}>
                <Col lg={12}>
                    <MUIDataTable
                        title={"Credits Purchases"}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
                </Col>
            </Row>
            <Row style={{marginBottom: '50px'}}>
                <Col lg={12}>
                    <MUIDataTable
                        title={"Credits Spent Overview"}
                        data={spentTableData}
                        columns={columns2}
                        options={options}
                    />
                </Col>
            </Row>
        </Container>


    return (
        <Container fluid className="main-content-container px-4">
            {content}
        </Container>
    )
}

ClientCreditOverview.propTypes = {
    /**
     * The small stats dataset.
     */
    smallStats: PropTypes.array
};

ClientCreditOverview.defaultProps = {
    smallStats: [
        {
            label: "Posts",
            value: "2,390",
            percentage: "4.7%",
            increase: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [1, 2, 1, 3, 5, 4, 7]
                }
            ]
        },
        {
            label: "Pages",
            value: "182",
            percentage: "12.4",
            increase: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(23,198,113,0.1)",
                    borderColor: "rgb(23,198,113)",
                    data: [1, 2, 3, 3, 3, 4, 4]
                }
            ]
        },
        {
            label: "Comments",
            value: "8,147",
            percentage: "3.8%",
            increase: false,
            decrease: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(255,180,0,0.1)",
                    borderColor: "rgb(255,180,0)",
                    data: [2, 3, 3, 3, 4, 3, 3]
                }
            ]
        },
        {
            label: "New Customers",
            value: "29",
            percentage: "2.71%",
            increase: false,
            decrease: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(255,65,105,0.1)",
                    borderColor: "rgb(255,65,105)",
                    data: [1, 7, 1, 3, 1, 4, 8]
                }
            ]
        },
        {
            label: "Subscribers",
            value: "17,281",
            percentage: "2.4%",
            increase: false,
            decrease: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgb(0,123,255,0.1)",
                    borderColor: "rgb(0,123,255)",
                    data: [3, 2, 3, 2, 4, 5, 4]
                }
            ]
        }
    ]
};

const ClientCreditOverviewForm = compose(
    withFirebase,
)(ClientCreditOverview);

export default ClientCreditOverviewForm;
