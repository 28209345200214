import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Form,
    FormSelect,
    FormInput
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";

import { SketchPicker } from 'react-color'
import {DropzoneArea} from "material-ui-dropzone";
import Loader from "react-loader-spinner";

function AddNewClient(props) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [code, setCode] = useState('')
    const [files, setFiles] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [color, setColor] = useState();
    const [textColor, setTextColor] = useState();
    const [logoUri, setLogoUri] = useState('');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [buttonDescription, setButtonDescription] = useState('Add Clients')

    const handleChange = color => {
        setColor(color)
    };

    const handleChangeText = textColor => {
        setTextColor(textColor)
    };

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Add New Client" subtitle="New Client" className="text-sm-left" />
            </Row>

            <Col lg="12">
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Airline Details</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row form>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Airline's Name</label>
                                    <FormInput
                                        id="name"
                                        placeholder="eq: Boeing"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Airline's Code</label>
                                    <FormInput
                                        id="code"
                                        placeholder="eq: QA"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Airline's Email</label>
                                    <FormInput
                                        id="email"
                                        placeholder="eq: airline@xyz.com"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Airline's Password</label>
                                    <FormInput
                                        id="pass"
                                        placeholder="eq: **********"
                                        value={pass}
                                        type="password"
                                        onChange={e => setPass(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col lg="12">
                                    <Row form>
                                        <label htmlFor="feLastName">Add airline's logo</label>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            dropzoneText={"Drag and drop the airline's logo here or click (480x100)"}
                                            onChange={(res) => setFiles(res)}
                                        />
                                    </Row>

                                </Col>
                            </Row>
                            <Row form style={{marginTop: 20}}>
                                <Col lg="3">
                                    <label htmlFor="feLastName">Select button's color</label>
                                    <SketchPicker width={250} color={color} onChange={handleChange} disableAlpha={true}/>
                                </Col>
                                <Col lg="3">
                                    <label htmlFor="feLastName">Select text's color</label>
                                    <SketchPicker width={250} color={textColor} onChange={handleChangeText} disableAlpha={true}/>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Row>
                <Col lg="4">
                    <Button theme="accent" size="lg" style={{marginLeft: '5%'}} disabled={isButtonDisabled} onClick={async () => {

                        if(document.getElementById('name').value !== ''
                            && document.getElementById('email').value !== ''
                            && document.getElementById('pass').value !== ''
                            && document.getElementById('code').value !== '') {

                            let name = document.getElementById('name').value
                            let email = document.getElementById('email').value
                            let pass = document.getElementById('pass').value
                            let code = document.getElementById('code').value
                            let hex = color.hex
                            let textHex = textColor.hex


                            if (name === email || name === pass || name === code ||
                                email === pass || email === code || pass === code) {
                                alert("Fields shouldn't match")
                            } else {
                                setIsButtonDisabled(true)
                                setButtonDescription('Loading...')
                                await props.firebase.doAddClientLogo(name, files[0])
                                    .then(async (res) => {
                                        await props.firebase.doAddNewClient(name, code, email, pass, hex, textHex, res)
                                            .then(r => {
                                                setIsButtonDisabled(false)
                                                setButtonDescription('Add Client')
                                                alert('Client added successfully')
                                                window.location.reload();
                                            })
                                            .catch(err => {
                                                setIsButtonDisabled(false)
                                                setButtonDescription('Add Client')
                                            })
                                    })
                            }
                        } else {
                            alert('You should complete all the fields in order to continue.')
                        }


                    }}>{buttonDescription}</Button>
                </Col>
            </Row>
        </>

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {content}
        </Container>
    )
}

const AddNewClientForm = compose(
    withFirebase,
)(AddNewClient);

export default AddNewClientForm;
