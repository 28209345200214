export default function() {
    return [
        {
            title: "Credit Overview",
            htmlBefore: '<i class="material-icons">receipt</i>',
            to: "/admin-credit-overview",
        },
        {
            title: "Add Client",
            htmlBefore: '<i class="material-icons">flight</i>',
            to: "/admin-add-client",
        },
        {
            title: "Add Assesor to Client",
            htmlBefore: '<i class="material-icons">group_work</i>',
            to: "/admin-add-assesor",
        },
        {
            title: "Add Job",
            htmlBefore: '<i class="material-icons">work</i>',
            to: "/admin-create-new-job",
        },
        {
            title: "Add Candidates",
            htmlBefore: '<i class="material-icons">group_add</i>',
            to: "/admin-add-candidate",
        },
        {
            title: "Add Test",
            htmlBefore: '<i class="material-icons">quiz</i>',
            to: "/admin-add-test",
        }
    ];
}
