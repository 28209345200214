import React from "react";
import PropTypes from "prop-types";
import './slider.css'

class Slider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            images: this.props.sliderData,
            currentIndex: 0,
            translateValue: 0
        }
    }

    goToPrevSlide = () => {
        if(this.state.currentIndex === 0)
            return;

        this.setState(prevState => ({
            currentIndex: prevState.currentIndex - 1,
            translateValue: prevState.translateValue + this.slideWidth()
        }))
    }

    goToNextSlide = () => {
        // Exiting the method early if we are at the end of the images array.
        // We also want to reset currentIndex and translateValue, so we return
        // to the first image in the array.
        if(this.state.currentIndex === this.state.images.length - 1) {
            return this.setState({
                currentIndex: 0,
                translateValue: 0
            })
        }

        // This will not run if we met the if condition above
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex + 1,
            translateValue: prevState.translateValue + -(this.slideWidth())
        }));
    }

    slideWidth = () => {
        return document.querySelector('.slide').clientWidth
    }

    render() {
        return (
            <div className="slider">
                <CloseButton closeDialog={this.props.closeDialog}/>
                <div className="slider-wrapper"
                     style={{
                         transform: `translateX(${this.state.translateValue}px)`,
                         transition: 'transform ease-out 0.45s',
                     }}>
                    {
                        this.state.images.map((image, i) => (
                            <Slide key={i} image={image}/>
                        ))
                    }
                </div>

                <LeftArrow
                    goToPrevSlide={this.goToPrevSlide}
                />

                <RightArrow
                    goToNextSlide={this.goToNextSlide}
                />
            </div>
        );
    }
}

Slider.propTypes = {
    sliderData: PropTypes.array,
    closeDialog: PropTypes.func
};

export default Slider;

const Slide = ({ image }) => {
    const styles = {
        // backgroundImage: `url(${image})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 60%'
    }
    return <div className="slide" style={styles}>
        <img src={image} style={{height: '100%', display:'block', margin: '0 auto', cursor:'pointer', zIndex: 100}}/>
    </div>
}

const CloseButton = (props) => {
    return (
        <div className="arrow" style={{right: '25px', cursor: 'pointer', position: 'absolute', zIndex: 999}} onClick={props.closeDialog}>
            <i className="fas fa-times fa-2x" style={{color: '#222'}} aria-hidden="true"/>
        </div>
    )
}

const LeftArrow = (props) => {
    return (
        <div className="backArrow arrow" onClick={props.goToPrevSlide}>
            <i className="fa fa-arrow-left fa-2x" aria-hidden="true"/>
        </div>
    );
}


const RightArrow = (props) => {
    return (
        <div className="nextArrow arrow" onClick={props.goToNextSlide}>
            <i className="fa fa-arrow-right fa-2x" aria-hidden="true"/>
        </div>
    );
}
