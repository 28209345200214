import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    CardFooter,
    Form,
    FormSelect,
    FormInput, ListGroup, ListGroupItem
} from "shards-react";
import MUIDataTable from "mui-datatables";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";
import PageTitle from "../../components/common/PageTitle";
import CustomToolbar from "../../components/common/CustomToolbar";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import Loader from "react-loader-spinner";

function CreditOverview(props) {

    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    const [airlines, setAirlines] = useState([]);
    const [airline, setAirline] = useState('');
    const [creditNo, setCreditNo] = useState('');
    const [note, setNote] = useState('');

    const columns = ["Client", "Purchase Date", "No. of credits purchased", "Invoice no. and note"];
    const options = {
        filterType: 'checkbox',
        downloadOptions: {filterOptions: {useDisplayedRowsOnly: true}},
        draggableColumns: {enabled: true},
        print: false,
        rowsPerPageOptions: [10, 25, 50, 100],
        setRowProps: row => {
            if (row[0]) {
                return {
                    style: { cursor: 'pointer' }
                };
            }
        },
        onRowsSelect: (rowsSelected, allRows) => {
            const selected = allRows.map(item => item.index);
            setSelectedRows(selected);
        },
        customSort: (data, colIndex, order) => {
            return data.sort((a, b) => {
                if (colIndex === 1) {
                    return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1: 1 ) * (order === 'desc' ? 1 : -1);
                } else if (colIndex === 2) {
                    return (parseInt(a.data[colIndex]) < parseInt(b.data[colIndex]) ? -1: 1 ) * (order === 'desc' ? 1 : -1);
                } else {
                    return (a.data[colIndex] < b.data[colIndex] ? -1: 1 ) * (order === 'desc' ? 1 : -1);
                }
            });
        },
        responsive: "scroll",
        fixedHeader: true,
    };

    useEffect(() => {

        async function getData() {
            let airliness = await props.firebase.doGetAirlines()
            let purchases = await props.firebase.doGetPurchasesHistory()
            let results = []
            purchases.forEach((purchase) => {
                // console.log(Date.parse(purchase.date))
                results.push({
                    client: purchase.client,
                    creditsNumber: (purchase.creditsNumber).toString(),
                    date: new Date(Date.parse(purchase.date)).toString(),
                    note: purchase.note,
                    uid: purchase.uid,
                })
            })
            setAirlines(airliness)
            setData(results)
            let tableRowsData = []
            results.forEach(result => {
                tableRowsData.push([result.client, result.date, result.creditsNumber, result.note])
            })
            setTableData(tableRowsData)
            setLoaded(true)
        }

        getData().then(r => console.log('Success!'));

    }, [props]);

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Credit Overview" subtitle="Credit" className="text-sm-left" />
            </Row>
            <Row>
                <Col md={6}>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Add New Credits</h6>
                        </CardHeader>
                        <ListGroup flush>
                            <ListGroupItem className="p-3">
                                <Row>
                                    <Col>
                                        <Form>
                                            <Col md="12" className="form-group">
                                                <label htmlFor="feInputState">Client's Name</label>
                                                <FormSelect id="clientName" onChange={(e) => setAirline(e.target.value)}>
                                                    <option>Choose...</option>
                                                    {
                                                        airlines.filter(airline => !airline['name'].includes('Admin')).map((airline) => (
                                                            <option>{airline['name']}</option>
                                                        ))
                                                    }
                                                </FormSelect>
                                            </Col>
                                            <Col md="12" className="form-group">
                                                <label htmlFor="feFirstName">Number of credits to add</label>
                                                <FormInput
                                                    id="credits"
                                                    placeholder="eq: 200"
                                                    value={creditNo}
                                                    onChange={e => setCreditNo(e.target.value)}
                                                />
                                            </Col>
                                            {/* Last Name */}
                                            <Col md="12" className="form-group">
                                                <label htmlFor="feLastName">Invoice number/notes</label>
                                                <FormInput
                                                    id="notes"
                                                    placeholder="eq: 1102 This is a note example"
                                                    value={note}
                                                    onChange={e => setNote(e.target.value)}
                                                />
                                            </Col>
                                        </Form>
                                    </Col>
                                </Row>
                                <div style={{display: 'flex', justifyContent: 'center',
                                    alignItems: 'center'}}>
                                    <Button theme="accent" size="md" onClick={() => {
                                        let clientName = document.getElementById('clientName').value
                                        let credits = document.getElementById('credits').value
                                        let notes = document.getElementById('notes').value

                                        clientName != '' && credits != '' && notes != '' ?
                                            props.firebase
                                                .doAddCreditsToAirline(clientName, credits, notes)
                                                .then(() => {
                                                    alert('Credits added successfully')
                                                    window.location.reload();
                                                })
                                                .catch(error => {
                                                    alert(error)
                                                })
                                            :
                                            alert('All fields are required')

                                    }}>Add credits</Button>
                                </div>

                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>


            <Row style={{marginBottom: '50px'}}>
                <Col lg={12}>
                    <MUIDataTable
                        title={"Credits Overview"}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
                </Col>
            </Row>
        </Container>


    return (
        <Container fluid className="main-content-container px-4">
            {content}
        </Container>
    )
}

CreditOverview.propTypes = {
    /**
     * The small stats dataset.
     */
    smallStats: PropTypes.array
};

CreditOverview.defaultProps = {
    smallStats: [
        {
            label: "Posts",
            value: "2,390",
            percentage: "4.7%",
            increase: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [1, 2, 1, 3, 5, 4, 7]
                }
            ]
        },
        {
            label: "Pages",
            value: "182",
            percentage: "12.4",
            increase: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(23,198,113,0.1)",
                    borderColor: "rgb(23,198,113)",
                    data: [1, 2, 3, 3, 3, 4, 4]
                }
            ]
        },
        {
            label: "Comments",
            value: "8,147",
            percentage: "3.8%",
            increase: false,
            decrease: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(255,180,0,0.1)",
                    borderColor: "rgb(255,180,0)",
                    data: [2, 3, 3, 3, 4, 3, 3]
                }
            ]
        },
        {
            label: "New Customers",
            value: "29",
            percentage: "2.71%",
            increase: false,
            decrease: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(255,65,105,0.1)",
                    borderColor: "rgb(255,65,105)",
                    data: [1, 7, 1, 3, 1, 4, 8]
                }
            ]
        },
        {
            label: "Subscribers",
            value: "17,281",
            percentage: "2.4%",
            increase: false,
            decrease: true,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
                {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgb(0,123,255,0.1)",
                    borderColor: "rgb(0,123,255)",
                    data: [3, 2, 3, 2, 4, 5, 4]
                }
            ]
        }
    ]
};

const CreditOverviewForm = compose(
    withFirebase,
)(CreditOverview);

export default CreditOverviewForm;
