import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Form,
    FormSelect,
    FormInput
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";

import { SketchPicker } from 'react-color'
import {DropzoneArea} from "material-ui-dropzone";
import Loader from "react-loader-spinner";

function AddNewAssesor(props) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [code, setCode] = useState('')
    const [loaded, setLoaded] = useState(true)

    const [airlines, setAirlines] = useState([])
    const [airline, setAirline] = useState('');
    const [airlineUid, setAirlineUid] = useState('');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [buttonDescription, setButtonDescription] = useState('Add Assesor')

    useEffect(() => {

        async function getData() {
            let airlines = await props.firebase.doGetAirlines();
            setAirlines(airlines)
            setLoaded(true)
        }

        getData().then(r => console.log('Success!'));

    }, [props]);

    useEffect(() => {
        airlines.forEach(each => {
            each['name'] === airline ?
                setAirlineUid(each['uid'])
                :
                console.log('Nope')
        })
    });

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Add Assesor to Client" subtitle="Add Assesor" className="text-sm-left" />
            </Row>

            <Col lg="12">
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Assesor Details</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row form>
                                <Col md="4" className="form-group">
                                    <label htmlFor="feInputState">Client's Name</label>
                                    <FormSelect id="clientName" onChange={(e) => setAirline(e.target.value)}>
                                        <option>Choose...</option>
                                        {
                                            airlines.filter(airline => !airline['name'].includes('Admin')).map((airline) => (
                                                <option>{airline['name']}</option>
                                            ))
                                        }
                                    </FormSelect>
                                </Col>
                                {
                                    airline == '' ?
                                        <></>
                                        :
                                        <Col md="4" className="form-group">
                                            <label htmlFor="feInputState">Client's UID</label>
                                            <FormInput
                                                id="uid"
                                                value={airlineUid}
                                                disabled={true}
                                            />
                                        </Col>
                                }
                            </Row>
                            <Row form>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Assesor's First Name</label>
                                    <FormInput
                                        id="first-name"
                                        placeholder="eq: John"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Assesor's Last Name</label>
                                    <FormInput
                                        id="last-name"
                                        placeholder="eq: Doe"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Assesor's Email</label>
                                    <FormInput
                                        id="email"
                                        placeholder="eq: name@xyz.com"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <label htmlFor="feLastName">Assesor's Password</label>
                                    <FormInput
                                        id="pass"
                                        placeholder="eq: **********"
                                        value={pass}
                                        type="password"
                                        onChange={e => setPass(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Row>
                <Col lg="4">
                    <Button theme="accent" size="lg" style={{marginLeft: '5%'}} disabled={isButtonDisabled} onClick={async () => {

                        setIsButtonDisabled(true)
                        setButtonDescription('Loading...')
                        let clientName = document.getElementById('clientName').value
                        let clientUid = document.getElementById('uid').value
                        let firstName = document.getElementById('first-name').value
                        let lastName = document.getElementById('last-name').value
                        let email = document.getElementById('email').value
                        let pass = document.getElementById('pass').value

                        console.log(clientName, clientUid, firstName, lastName, email, pass)
                        clientName != '' && firstName != '' && lastName != '' && email != '' && pass != '' ?
                            props.firebase
                                .doAddNewAssesor(clientName, clientUid, firstName, lastName, email, pass)
                                .then(() => {
                                    setIsButtonDisabled(false)
                                    setButtonDescription('Add Assesor')
                                    alert('Assesor added successfully')
                                    window.location.reload();
                                })
                                .catch(error => {
                                    alert(error)
                                })
                            :
                            alert('All fields are required')

                    }}>{buttonDescription}</Button>
                </Col>
            </Row>
        </>

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {content}
        </Container>
    )
}

const AddNewAssesorForm = compose(
    withFirebase,
)(AddNewAssesor);

export default AddNewAssesorForm;
