import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  ListGroupItem,
  Form,
  FormInput,
  ListGroup,
  Alert,
  Slider as Sliding,
  FormTextarea,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { compose } from "recompose";
import { withFirebase } from "../components/Firebase";
import Slider from "../components/common/Slider";
import Switch from "react-switch";

// import Slider from 'react-animated-slider';
// import 'react-animated-slider/build/horizontal.css';
// import '../assets/slider.css';

function ClientCandidateDetail(props) {
  const [candidates, setCandidates] = useState(props.location.state.candidates);
  const [currentCandidate, setCurrentCandidate] = useState(candidates[0]);
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(1);
  const [notes, setNotes] = useState(
    candidates[currentCandidateIndex - 1].notes
  );
  const [assesorScore, setAssesorScore] = useState(
    candidates[currentCandidateIndex - 1].assesorScore
  );
  const [oldNotes, setOldNotes] = useState(
    candidates[currentCandidateIndex - 1].notes
  );
  const [video1, setVideo1] = useState(0);
  const [video2, setVideo2] = useState(0);
  const [audio1, setAudio1] = useState(0);
  const [audio2, setAudio2] = useState(0);
  const [audioTotalScore, setAudioTotalScore] = useState(0);
  const [videoTotalScore, setVideoTotalScore] = useState(0);
  const [logicalReasoning, setLogicalReasoning] = useState(0);
  const [english, setEnglish] = useState(0);
  const [math, setMath] = useState(0);
  const [logicalEnglishMath, setLogicalEnglishMath] = useState(0);
  const picturesArray = [
    candidates[currentCandidateIndex - 1].profilePicture,
    candidates[currentCandidateIndex - 1].profilePicture2,
    candidates[currentCandidateIndex - 1].idPicture,
  ].filter((n) => n);
  const [picturesIndexInArray, setPicturesIndexInArray] = useState(0);

  const [isImageOpened, setIsImageOpened] = useState(false);
  const [isAssesorsNoteEditable, setIsAssesorsNoteEditable] = useState(false);
  const [imageStatus, setImageStatus] = useState("firstLoading");

  const [isAlertBoxShown, setIsAlertBoxShown] = useState(
    typeof props.location.state.isAlertBoxShown == "undefined" ? false : true
  );

  const [canEdit, setCanEdit] = React.useState(false);
  console.log("CANDITATI", candidates);

  function handleShowDialog() {
    setIsImageOpened(!isImageOpened);
  }

  function handleImageLoaded() {
    setImageStatus("loaded");
  }

  function calculate_age(dob) {
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  useEffect(() => {
    setVideo1(candidates[currentCandidateIndex - 1].video1Score);
    setVideo2(candidates[currentCandidateIndex - 1].video2Score);
    setAudio1(candidates[currentCandidateIndex - 1].audio1Score);
    setAudio2(candidates[currentCandidateIndex - 1].audio2Score);

    setAudioTotalScore(
      Math.round(
        (candidates[currentCandidateIndex - 1].audio2Score +
          candidates[currentCandidateIndex - 1].audio1Score) /
          2 -
          1
      )
    );
    setVideoTotalScore(
      Math.round(
        (candidates[currentCandidateIndex - 1].video1Score +
          candidates[currentCandidateIndex - 1].video2Score) /
          2 -
          1
      )
    );

    setLogicalReasoning(
      Math.round(
        (5 * candidates[currentCandidateIndex - 1].logicalReasoningScore) / 100
      )
    );
    setEnglish(
      Math.round((5 * candidates[currentCandidateIndex - 1].englishScore) / 100)
    );
    setMath(
      Math.round((5 * candidates[currentCandidateIndex - 1].mathScore) / 100)
    );

    let logicalEnglishMath = Math.round(
      (parseInt(candidates[currentCandidateIndex - 1].mathScore) +
        parseInt(candidates[currentCandidateIndex - 1].englishScore) +
        parseInt(candidates[currentCandidateIndex - 1].logicalReasoningScore)) /
        3
    );
    setLogicalEnglishMath(logicalEnglishMath);
  });

  useEffect(() => {
    setTimeout(() => {
      setIsAlertBoxShown(false);
    }, 10000);

    console.log(
      "FOL-------------------------------------------------:- ",
      props.location.state.candidates
    );
  }, []);

  function handleChange() {
    canEdit === true ? setCanEdit(false) : setCanEdit(true);
  }

  function overallChange(event) {
    setAssesorScore(parseInt(event.target.value));
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {isAlertBoxShown == true ? (
        <Container
          fluid
          className="px-0"
          style={{
            backgroundColor: "#17c671",
            position: "fixed",
            zIndex: "200",
            width: "80%",
          }}
        >
          <Alert className="mb-0" style={{ backgroundColor: "#17c671" }}>
            <i className="fa fa-info mx-2"></i> Scores saved successfully{" "}
            <i
              className="fas fa-times fa-1x"
              style={{ color: "white", float: "right", cursor: "pointer" }}
              onClick={() => {
                setIsAlertBoxShown(false);
              }}
              aria-hidden="true"
            />
          </Alert>
        </Container>
      ) : (
        <></>
      )}
      {isImageOpened == true ? (
        <div style={{ marginTop: 30 }}>
          <Slider sliderData={picturesArray} closeDialog={handleShowDialog} />
        </div>
      ) : (
        <></>
      )}
      <Row noGutters style={{ marginTop: 15 }}>
        <i
          className="material-icons"
          style={{
            fontWeight: 400,
            fontSize: 20,
            color: "#3D5170",
            marginRight: 15,
            paddingTop: 12,
            cursor: "pointer",
          }}
          onClick={() => {
            props.history.push({
              pathname: "/job-detail",
              state: { job: props.location.state.jobData },
            });
          }}
        >
          keyboard_backspace
        </i>
        <span
          style={{
            fontSize: 20,
            marginTop: "10px",
            float: "left",
            cursor: "pointer",
          }}
          onClick={() => {
            props.history.push({
              pathname: "/job-detail",
              state: { job: props.location.state.jobData },
            });
          }}
        >
          Return to job details page
        </span>
      </Row>
      <Row noGutters className="page-header py-4">
        <PageTitle
          noGutters
          sm="4"
          className="mb-sm-0"
          title="Candidate Results"
        />
      </Row>
      {candidates.length > 1 ? (
        <Row>
          <Col lg="12">
            <Card small className="card-post mb-4">
              <CardBody>
                <Row style={{ marginTop: "-10px", marginBottom: "-30px" }}>
                  <Col lg={4}>
                    <h5
                      className="card-title"
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        float: "left",
                      }}
                      onClick={() => {
                        if (currentCandidateIndex !== 1) {
                          setCurrentCandidateIndex(currentCandidateIndex - 1);
                          setPicturesIndexInArray(0);
                          setImageStatus("firstLoading");
                        }
                      }}
                    >
                      &#60; Previous
                    </h5>
                  </Col>
                  <Col lg={4}>
                    <p
                      className="card-text text-muted"
                      style={{ marginLeft: "40%", fontSize: "15px" }}
                    >
                      {currentCandidateIndex} out of {candidates.length}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <h5
                      className="card-title"
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        float: "right",
                      }}
                      onClick={() => {
                        if (currentCandidateIndex !== candidates.length) {
                          setCurrentCandidateIndex(currentCandidateIndex + 1);
                          setPicturesIndexInArray(0);
                          setImageStatus("firstLoading");
                        }
                      }}
                    >
                      Next &#62;
                    </h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row>
        {/*<p>{candidates[currentCandidateIndex - 1].candidateUsername}</p>*/}
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Candidate's details</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <Row>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Job name
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {props.location.state.jobData["title"]}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Assesment
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {candidates[currentCandidateIndex - 1].assesment}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Candidate username
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {
                          candidates[currentCandidateIndex - 1]
                            .candidateUsername
                        }
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Email
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {candidates[currentCandidateIndex - 1].email}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        First name
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {candidates[currentCandidateIndex - 1].firstName}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Last name
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {candidates[currentCandidateIndex - 1].lastName}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        City
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {candidates[currentCandidateIndex - 1].city}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Age
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {parseInt(
                          calculate_age(
                            new Date(
                              candidates[
                                currentCandidateIndex - 1
                              ].dateOfBirth.split("/")[2]
                            ),
                            parseInt(
                              candidates[
                                currentCandidateIndex - 1
                              ].dateOfBirth.split("/")[1]
                            ),
                            parseInt(
                              candidates[
                                currentCandidateIndex - 1
                              ].dateOfBirth.split("/")[0]
                            )
                          )
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px", marginBottom: "-30px" }}>
                    <Col>
                      <h5 className="card-title" style={{ fontSize: "15px" }}>
                        Date of birth
                      </h5>
                    </Col>
                    <Col>
                      <p
                        className="card-text text-muted"
                        style={{ marginTop: "3px", float: "right" }}
                      >
                        {candidates[currentCandidateIndex - 1].dateOfBirth}
                      </p>
                    </Col>
                  </Row>
                </Col>
                {candidates[currentCandidateIndex - 1].assesment ==
                "Test not taken" ? (
                  <></>
                ) : (
                  <Col lg={6}>
                    <Row style={{ height: "230px", marginTop: 13 }}>
                      <Col sm={12}>
                        <img
                          style={{
                            maxHeight: "217px",
                            display: "block",
                            margin: "0 auto",
                            cursor: "pointer",
                            zIndex: 100,
                          }}
                          onLoad={handleImageLoaded}
                          onClick={handleShowDialog}
                          src={picturesArray[picturesIndexInArray]}
                          alt={"Picture"}
                        />
                        {imageStatus == "firstLoading" ? (
                          // <p style={{textAlign: 'center', zIndex: 100}}>Loading...</p>
                          <Loader
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10%",
                            }}
                            type="TailSpin"
                            color="#00BFFF"
                            height={50}
                            width={50}
                          />
                        ) : imageStatus == "loading" ? (
                          // <p style={{textAlign: 'center', zIndex: 100, marginTop:'-30%'}}>Loading...</p>
                          <Loader
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 101,
                              marginTop: "-30%",
                            }}
                            type="TailSpin"
                            color="#00BFFF"
                            height={50}
                            width={50}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        height: "20px",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Col sm={5}>
                        {picturesArray.length > 1 ? (
                          <i
                            className="fa fa-arrow-left fa-1x"
                            aria-hidden="true"
                            style={{
                              fontSize: "25px",
                              cursor: "pointer",
                              float: "right",
                            }}
                            onClick={() => {
                              if (picturesIndexInArray !== 0) {
                                setPicturesIndexInArray(
                                  picturesIndexInArray - 1
                                );
                                setImageStatus("loading");
                              }
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col sm={2}>
                        <i
                          className="fas fa-expand-arrows-alt fa-2x"
                          aria-hidden="true"
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "dark-grey",
                            marginLeft: "25px",
                          }}
                          onClick={handleShowDialog}
                        />
                      </Col>
                      <Col sm={5}>
                        {picturesArray.length > 1 ? (
                          <i
                            className="fa fa-arrow-right fa-1x"
                            aria-hidden="true"
                            style={{
                              fontSize: "25px",
                              cursor: "pointer",
                              float: "left",
                            }}
                            onClick={() => {
                              if (
                                picturesIndexInArray !==
                                picturesArray.length - 1
                              ) {
                                setPicturesIndexInArray(
                                  picturesIndexInArray + 1
                                );
                                setImageStatus("loading");
                              }
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {candidates[currentCandidateIndex - 1].assesment == "Test not taken" ? (
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">This candidate has not taken the test yet!</h6>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
      ) : (
        <>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Row style={{ marginLeft: 0 }}>
                    <h6 className="m-0">Assesor notes</h6>
                  </Row>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  {isAssesorsNoteEditable ? (
                    <Row>
                      <Col md="10">
                        <FormTextarea
                          style={{
                            marginLeft: "20px",
                            marginTop: "15px",
                            cursor: "pointer",
                          }}
                          id="notes"
                          value={notes}
                          maxlength={500}
                          resize={true}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </Col>
                      <Col md="4" style={{ marginLeft: "17px" }}>
                        <Row>
                          <Button
                            theme="danger"
                            size="md"
                            style={{ marginLeft: "17px", marginTop: "15px" }}
                            onClick={async () => {
                              setIsAssesorsNoteEditable(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            theme="success"
                            size="md"
                            style={{ marginLeft: "15px", marginTop: "15px" }}
                            onClick={async () => {
                              let notes = document.getElementById("notes")
                                .value;
                              setIsAssesorsNoteEditable(false);
                              setOldNotes(notes);
                              await props.firebase
                                .doAddNotesToResult(
                                  candidates[currentCandidateIndex - 1]
                                    .candidateUsername,
                                  notes
                                )
                                .then((res) => {
                                  console.log("Succes!");
                                })
                                .catch((error) => {
                                  console.log(error.message);
                                });
                            }}
                          >
                            Save
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      {oldNotes.length == 0 ? (
                        <></>
                      ) : (
                        <Col md="12">
                          <p
                            style={{
                              fontWeight: "normal",
                              marginLeft: "20px",
                              marginTop: "15px",
                            }}
                          >
                            {oldNotes}
                          </p>
                        </Col>
                      )}
                      <Col>
                        <Button
                          theme="white"
                          size="md"
                          style={{
                            marginLeft: "15px",
                            marginTop: oldNotes.length === 0 ? "15px" : "0px",
                          }}
                          onClick={async () => {
                            setIsAssesorsNoteEditable(true);
                          }}
                        >
                          {oldNotes.length === 0 ? "Add note" : "Edit note"}
                          <i
                            className="material-icons"
                            style={{
                              fontSize: 12,
                              color: "#3D5170",
                              marginLeft: 10,
                              cursor: "pointer",
                            }}
                          >
                            mode_edit
                          </i>
                        </Button>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Test scores</h6>
                </CardHeader>
                <CardBody style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              marginTop: -15,
                              backgroundColor: "#FBFBFB",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "100%",
                              height: 50,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                paddingRight: 10,
                                width: "100%",
                                justifyContent: "space-around",
                              }}
                            >
                              <div></div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Not competent
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Marginal skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Adequate skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Good skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Superior skills
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p
                                style={{
                                  fontWeight: "500",
                                  color: "#007bff",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/candidate-results-media-player",
                                    state: {
                                      media: {
                                        audio1:
                                          candidates[currentCandidateIndex - 1]
                                            .audio1,
                                        audio2:
                                          candidates[currentCandidateIndex - 1]
                                            .audio2,
                                        video1:
                                          candidates[currentCandidateIndex - 1]
                                            .video1,
                                        video2:
                                          candidates[currentCandidateIndex - 1]
                                            .video2,
                                        uid:
                                          candidates[currentCandidateIndex - 1]
                                            .uid,
                                      },
                                      type: "audio/video",
                                      scores:
                                        candidates[currentCandidateIndex - 1]
                                          .scores,
                                      candidates:
                                        props.location.state.candidates,
                                      jobData: props.location.state.jobData,
                                    },
                                  });
                                }}
                              >
                                Audio
                              </p>
                            </div>
                            <div style={{ paddingRight: 10, width: "100%" }}>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={[(100 * (audioTotalScore + 1)) / 5]}
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p
                                style={{
                                  fontWeight: "500",
                                  color: "#007bff",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/candidate-results-media-player",
                                    state: {
                                      media: {
                                        audio1:
                                          candidates[currentCandidateIndex - 1]
                                            .audio1,
                                        audio2:
                                          candidates[currentCandidateIndex - 1]
                                            .audio2,
                                        video1:
                                          candidates[currentCandidateIndex - 1]
                                            .video1,
                                        video2:
                                          candidates[currentCandidateIndex - 1]
                                            .video2,
                                        uid:
                                          candidates[currentCandidateIndex - 1]
                                            .uid,
                                      },
                                      type: "audio/video",
                                      scores:
                                        candidates[currentCandidateIndex - 1]
                                          .scores,
                                      candidates:
                                        props.location.state.candidates,
                                      jobData: props.location.state.jobData,
                                    },
                                  });
                                }}
                              >
                                Video
                              </p>
                            </div>
                            <div style={{ paddingRight: 10, width: "100%" }}>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={[(100 * (videoTotalScore + 1)) / 5]}
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p style={{ fontWeight: "500" }}>English</p>
                            </div>
                            <div style={{ paddingRight: 10, width: "100%" }}>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={[
                                  candidates[currentCandidateIndex - 1]
                                    .englishScore,
                                ]}
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p style={{ fontWeight: "500" }}>Math</p>
                            </div>
                            {/*<div style={{paddingRight: 10, paddingTop: 12, color: 'white', backgroundColor: '#2b85e0', width: candidates[currentCandidateIndex - 1].mathScore+'%', textAlign: 'right'}}>{candidates[currentCandidateIndex - 1].mathScore+'%'}</div>*/}
                            <div style={{ paddingRight: 10, width: "100%" }}>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={[
                                  candidates[currentCandidateIndex - 1]
                                    .mathScore,
                                ]}
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p style={{ fontWeight: "500" }}>
                                Logical reasoning
                              </p>
                            </div>
                            {/*<div style={{paddingRight: 10, paddingTop: 12, color: 'white', backgroundColor: '#2b85e0', width: candidates[currentCandidateIndex - 1].logicalReasoningScore+'%', textAlign: 'right'}}>{candidates[currentCandidateIndex - 1].logicalReasoningScore+'%'}</div>*/}
                            <div style={{ paddingRight: 10, width: "100%" }}>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={[
                                  candidates[currentCandidateIndex - 1]
                                    .logicalReasoningScore,
                                ]}
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                          {/*<div style={{display: 'flex',flexDirection: 'row', width: '100%', height: '40px'}}>*/}
                          {/*    <div style={{marginTop: 20, width: '13.5%'}}><p style={{fontWeight: '500'}}>Total score</p></div>*/}
                          {/*    /!*<div style={{paddingRight: 10, paddingTop: 12, color: 'white', backgroundColor: '#2b85e0', width: logicalEnglishMath+'%', textAlign: 'right'}}>{logicalEnglishMath+'%'}</div>*!/*/}
                          {/*    <div style={{paddingRight: 10, width: '100%'}}>*/}
                          {/*        <Sliding*/}
                          {/*            theme='primary'*/}
                          {/*            className="my-4"*/}
                          {/*            // pips={{ mode: "steps", stepped: true, density: 3 }}*/}
                          {/*            animate={true}*/}
                          {/*            disabled={true}*/}
                          {/*            connect={[true, false]}*/}
                          {/*            start={[logicalEnglishMath]}*/}
                          {/*            range={{ min: 0, max: 100 }}*/}
                          {/*            tooltips*/}
                          {/*            style={{width: '100%'}}*/}
                          {/*        />*/}
                          {/*    </div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/*<p>{candidates[currentCandidateIndex - 1].candidateUsername}</p>*/}
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Row className="m-0">
                    <h6 className="m-0" style={{ paddingRight: 20 }}>
                      Assesor score
                    </h6>
                    <Switch
                      onChange={handleChange}
                      checked={canEdit}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={20}
                      width={40}
                    />
                  </Row>
                </CardHeader>
                <CardBody style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              marginTop: -15,
                              backgroundColor: "#FBFBFB",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "100%",
                              height: 50,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                paddingRight: 10,
                                width: "100%",
                                justifyContent: "space-around",
                              }}
                            >
                              <div></div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Not competent
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Marginal skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Adequate skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Good skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Superior skills
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p style={{ fontWeight: "500" }}>Total score</p>
                            </div>
                            {/*<div style={{paddingRight: 10, paddingTop: 12, color: 'white', backgroundColor: '#2b85e0', width: Math.round((parseInt(candidates[currentCandidateIndex - 1].englishScore) + parseInt(candidates[currentCandidateIndex - 1].mathScore) + parseInt(candidates[currentCandidateIndex - 1].logicalReasoningScore) + ((100 * (audioTotalScore+1)) / 5) + ((100 * (videoTotalScore+1)) / 5))/5) + '%', textAlign: 'right'}}>*/}
                            {/*    {Math.round((parseInt(candidates[currentCandidateIndex - 1].englishScore) + parseInt(candidates[currentCandidateIndex - 1].mathScore) + parseInt(candidates[currentCandidateIndex - 1].logicalReasoningScore) + ((100 * (audioTotalScore+1)) / 5) + ((100 * (videoTotalScore+1)) / 5))/5)+'%'}</div>*/}
                            <div
                              style={{
                                display: "flex",
                                paddingRight: 10,
                                width: "100%",
                                justifyContent: "space-around",
                                marginTop: 20,
                              }}
                            >
                              <input
                                disabled={!canEdit}
                                defaultChecked={assesorScore === 1}
                                type="radio"
                                value="1"
                                name="overall"
                                onChange={overallChange}
                              />
                              <input
                                disabled={!canEdit}
                                defaultChecked={assesorScore === 2}
                                type="radio"
                                value="2"
                                name="overall"
                                onChange={overallChange}
                              />
                              <input
                                disabled={!canEdit}
                                defaultChecked={assesorScore === 3}
                                type="radio"
                                value="3"
                                name="overall"
                                onChange={overallChange}
                              />
                              <input
                                disabled={!canEdit}
                                defaultChecked={assesorScore === 4}
                                type="radio"
                                value="4"
                                name="overall"
                                onChange={overallChange}
                              />
                              <input
                                disabled={!canEdit}
                                defaultChecked={assesorScore === 5}
                                type="radio"
                                value="5"
                                name="overall"
                                onChange={overallChange}
                              />
                            </div>
                          </div>
                          {canEdit ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                height: "50px",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                theme="danger"
                                size="md"
                                style={{ marginTop: "15px" }}
                                onClick={async () => {
                                  setCanEdit(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                theme="success"
                                size="md"
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "15px",
                                }}
                                onClick={async () => {
                                  setCanEdit(false);
                                  await props.firebase
                                    .doAddAssesorScoreToTest(
                                      props,
                                      candidates[currentCandidateIndex - 1].uid,
                                      assesorScore
                                    )
                                    .then((res) => {
                                      console.log("Succes!");
                                    })
                                    .catch((error) => {
                                      console.log(error.message);
                                    });
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/*<p>{candidates[currentCandidateIndex - 1].candidateUsername}</p>*/}
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Total scores of all tests</h6>
                </CardHeader>
                <CardBody style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              marginTop: -15,
                              backgroundColor: "#FBFBFB",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "100%",
                              height: 50,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                paddingRight: 10,
                                width: "100%",
                                justifyContent: "space-around",
                              }}
                            >
                              <div></div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Not competent
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Marginal skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Adequate skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Good skills
                                </p>
                              </div>
                              <div
                                className="border-0"
                                style={{
                                  height: "30px",
                                  color: "black",
                                  paddingTop: 15,
                                }}
                              >
                                <p style={{ fontWeight: "normal" }}>
                                  Superior skills
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div style={{ marginTop: 20, width: "13.5%" }}>
                              <p style={{ fontWeight: "500" }}>Total score</p>
                            </div>
                            {/*<div style={{paddingRight: 10, paddingTop: 12, color: 'white', backgroundColor: '#2b85e0', width: Math.round((parseInt(candidates[currentCandidateIndex - 1].englishScore) + parseInt(candidates[currentCandidateIndex - 1].mathScore) + parseInt(candidates[currentCandidateIndex - 1].logicalReasoningScore) + ((100 * (audioTotalScore+1)) / 5) + ((100 * (videoTotalScore+1)) / 5))/5) + '%', textAlign: 'right'}}>*/}
                            {/*    {Math.round((parseInt(candidates[currentCandidateIndex - 1].englishScore) + parseInt(candidates[currentCandidateIndex - 1].mathScore) + parseInt(candidates[currentCandidateIndex - 1].logicalReasoningScore) + ((100 * (audioTotalScore+1)) / 5) + ((100 * (videoTotalScore+1)) / 5))/5)+'%'}</div>*/}
                            <div style={{ paddingRight: 10, width: "100%" }}>
                              <Sliding
                                theme="primary"
                                className="my-4"
                                // pips={{ mode: "steps", stepped: true, density: 3 }}
                                animate={true}
                                disabled={true}
                                connect={[true, false]}
                                start={[
                                  Math.round(
                                    (parseInt(
                                      candidates[currentCandidateIndex - 1]
                                        .englishScore
                                    ) +
                                      parseInt(
                                        candidates[currentCandidateIndex - 1]
                                          .mathScore
                                      ) +
                                      parseInt(
                                        candidates[currentCandidateIndex - 1]
                                          .logicalReasoningScore
                                      ) +
                                      (100 * (audioTotalScore + 1)) / 5 +
                                      (100 * (videoTotalScore + 1)) / 5) /
                                      5
                                  ),
                                ]}
                                range={{ min: 0, max: 100 }}
                                tooltips
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

const ClientCandidateDetailOverviewForm = compose(withFirebase)(
  ClientCandidateDetail
);

export default ClientCandidateDetailOverviewForm;
