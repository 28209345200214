import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button as CardButton } from "shards-react";
// import { withRouter } from 'react-router-dom';
import logo from '../assets/screen logo vertical large.png'

import { withFirebase } from '../components/Firebase';
import { compose } from 'recompose';

// import renderHTML from 'react-render-html';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: 'lightgray',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function SignIn(props) {

    const classes = useStyles();
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={5} md={8} className={classes.image}>
                {
                    window.innerWidth > 800 ? <>
                            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                <img src={logo} width='30%' height='30%' style={{marginTop: '17%'}}/>
                            </div>
                        </> :
                        <></>
                }
            </Grid>
            {/* Add landing in the upper Grid */}
            <Grid item xs={12} sm={9} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper} style={{marginTop: '35%'}}>
                    {/*<Avatar className={classes.avatar}>*/}
                    {/*    /!*<LockOutlinedIcon />*!/*/}
                    {/*</Avatar>*/}
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            style={{marginTop: 20}}
                            onClick={() => {
                                email != '' && password != '' ?
                                    props.firebase
                                        .doSignInWithEmailAndPassword(props, email, password)
                                        .then(() => {
                                            // window.location.href = '/signin-phone'
                                            // props.history.push({
                                            //     pathname: '/signin-phone',
                                            //     state: { email: email, password: password }
                                            // })
                                        })
                                        .catch(error => {
                                            alert(error)
                                        })
                                    :
                                    alert('All fields are required')
                            }}
                        >
                            Sign In
                        </Button>
                        {/*<Grid container>*/}
                        {/*    <Grid item xs>*/}
                        {/*        <Link href="/" variant="body2">*/}
                        {/*            Forgot password?*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link href='/' variant="body2">*/}
                        {/*            {"Don't have an account? Sign Up"}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        <Box mt={5}>
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

const SignInForm = compose(
    withFirebase,
)(SignIn);

export default SignInForm;

// export { SignInForm };
