import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, DefaultAdminLayout, Sign } from "./layouts";

// Route Views
import ClientJobDetail from "./views/ClientJobDetail";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import SignIn from "./views/SignIn";
import SignInPhone from "./views/SignInPhone";
import CreateNewJob from "./views/Admin/CreateNewJob";
import AddNewCandidate from "./views/Admin/AddNewCandidate";
import AddNewTest from "./views/Admin/AddNewTest";
import CreditOverview from "./views/Admin/CreditOverview";
import ClientDashboard from "./views/ClientDashboard";
import ClientCandidateDetail from "./views/ClientCandidateDetail";
import ClientCreditOverview from "./views/ClientCreditOverview";
import ClientCandidateResultsMediaPlayer from "./views/ClientCandidateResultsMediaPlayer";
import AddNewClient from "./views/Admin/AddNewClient";
import AddNewAssesor from "./views/Admin/AddNewAssesor";
import AppSupport from "./views/Admin/AppSupport";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/signin" />,
  },
  {
    path: "/user-profile-lite",
    layout: DefaultAdminLayout,
    component: UserProfileLite,
  },
  {
    path: "/add-new-post",
    layout: DefaultAdminLayout,
    component: AddNewPost,
  },
  {
    path: "/errors",
    layout: DefaultAdminLayout,
    component: Errors,
  },
  {
    path: "/components-overview",
    layout: DefaultAdminLayout,
    component: ComponentsOverview,
  },
  {
    path: "/tables",
    layout: DefaultAdminLayout,
    component: Tables,
  },
  {
    path: "/blog-posts",
    layout: DefaultAdminLayout,
    component: BlogPosts,
  },
  {
    path: "/signin",
    layout: Sign,
    component: SignIn,
  },
  {
    path: "/signin-phone",
    layout: Sign,
    component: SignInPhone,
  },
  {
    path: "/admin-create-new-job",
    layout: DefaultAdminLayout,
    component: CreateNewJob,
  },
  {
    path: "/admin-add-client",
    layout: DefaultAdminLayout,
    component: AddNewClient,
  },
  {
    path: "/admin-add-assesor",
    layout: DefaultAdminLayout,
    component: AddNewAssesor,
  },
  {
    path: "/admin-add-candidate",
    layout: DefaultAdminLayout,
    component: AddNewCandidate,
  },
  {
    path: "/admin-add-test",
    layout: DefaultAdminLayout,
    component: AddNewTest,
  },
  {
    path: "/admin-credit-overview",
    layout: DefaultAdminLayout,
    component: CreditOverview,
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: ClientDashboard,
  },
  {
    path: "/job-detail",
    layout: DefaultLayout,
    component: ClientJobDetail,
  },
  {
    path: "/candidate-detail",
    layout: DefaultLayout,
    component: ClientCandidateDetail,
  },
  {
    path: "/candidate-results-media-player",
    layout: DefaultLayout,
    component: ClientCandidateResultsMediaPlayer,
  },
  {
    path: "/credits-overview",
    layout: DefaultLayout,
    component: ClientCreditOverview,
  },
  {
    path: "/app_support",
    layout: Sign,
    component: AppSupport,
  },
];
