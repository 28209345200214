import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import {compose} from "recompose";
import {withFirebase} from "../../../Firebase";

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      airline: {}
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  componentDidMount() {
    this.props.firebase.doGetCurrentAirline()
        .then((res) => this.setState({airline: res}))
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3"  style={{marginTop: '10%'}}>
          <span className="d-none d-md-inline-block">
            {typeof this.state.airline.name == 'undefined' ? "Admin" : this.state.airline.name}
          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} small open={this.state.visible}>
          {/*<DropdownItem tag={Link} to="user-profile">*/}
          {/*  <i className="material-icons">&#xE7FD;</i> Profile*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem tag={Link} to="edit-user-profile">*/}
          {/*  <i className="material-icons">&#xE8B8;</i> Edit Profile*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem tag={Link} to="file-manager-list">*/}
          {/*  <i className="material-icons">&#xE2C7;</i> Files*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem tag={Link} to="transaction-history">*/}
          {/*  <i className="material-icons">&#xE896;</i> Transactions*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem divider />*/}
          <DropdownItem tag={Link} to="signin" onClick={() => {
            this.props.firebase.doSignOut()
          }} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

const UserActionsForm = compose(
    withFirebase,
)(UserActions);

export default UserActionsForm;

