import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Form,
    FormSelect,
    FormInput, Card, Alert
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";
import NewCandidateDetails from "../../components/user-profile-lite/NewCandidateDetails";
import {DropzoneArea} from "material-ui-dropzone";
import MUIDataTable from "mui-datatables";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from "react-loader-spinner";

function AddNewCandidate(props) {

    const [airlines, setAirlines] = useState([])
    const [loaded, setLoaded] = useState(false)

    const [airline, setAirline] = useState('');
    const [airlineUid, setAirlineUid] = useState('')
    const [jobs, setJobs] = useState([]);
    const [job, setJob] = useState([]);
    const [jobUid, setJobUid] = useState([]);

    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [texts, setTexts] = useState([]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [buttonDescription, setButtonDescription] = useState('Add Candidates');

    const columns = ["First Name", "Last Name", "City", "DOB", "Email", "Applied for", "Type", "Username", "Password"];

    const [tableData, setTableData] = useState([])

    const options = {
        filterType: 'checkbox',
    };

    useEffect(() => {
        airlines.forEach(each => {
            each['name'] === airline ?
                setAirlineUid(each['uid'])
                :
                console.log('Nope')
        })

        async function fetchAirlineJobs() {
            let jobList = await props.firebase.doFetchAirlineJobsByUid(airlineUid)
            return jobList
        }

        if(airline != '' && jobs.length == 0) {
            fetchAirlineJobs().then(res => setJobs(res))
        }
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = (acceptedFiles) => {
        setFiles(acceptedFiles);
        setOpen(false);

        acceptedFiles.length == 0 ?
            setTableData([])
            :
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = () => {
                    const str = reader.result;

                    // update files contents
                    setTexts((t) => [...t, str]);
                }
                reader.readAsText(file);
            })
    }

    useEffect(() => {
        let stringged_csv = String(texts[texts.length-1]).replace(/\n/g, ",")
        let arrayed_csv = stringged_csv.split(",")
        const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));
        let chunked_csv_array = array_chunks(arrayed_csv, 7)

        // Alter the last field on each array (Shouldn't be \r at the end)
        chunked_csv_array.forEach(chunk => {
            String(chunk[6]).replace(/[\r]/g, '');
        })

        // Alter data with username and pass (add in each array a field for these)
        chunked_csv_array.forEach((chunk, index) => {
            if(index == 0) {
                chunk[7] = 'Username'
                chunk[8] = 'Password'
            } else {
                chunk[7] = chunk[0] + chunk[1] + Math.floor(100000 + Math.random() * 900000)
                chunk[8] = "QA" + Math.floor(100000 + Math.random() * 900000)
            }
        })

        // Present mui datatable with all these datas
        chunked_csv_array.shift()
        console.log(chunked_csv_array)
        setTableData(chunked_csv_array)
        // localStorage.setItem('tableData', JSON.stringify(chunked_csv_array))

        // TO DO: Let dropzone handle only 1 file (see documentation)
    }, [texts]);

    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {

        async function getData() {
            let airlines = await props.firebase.doGetAirlines();
            setAirlines(airlines)
            setLoaded(true)
        }

        getData().then(r => console.log('Success!'));

    }, [props]);

    // useEffect(() => {
    //     function readOutLoud(message) {
    //         setTimeout(() => {
    //             let voices = window.speechSynthesis.getVoices()
    //         }, 2000);
    //
    //         var speech = new SpeechSynthesisUtterance();
    //
    //         // Set the text and voice attributes.
    //         speech.lang = 'en-IE'
    //         speech.text = message;
    //         speech.volume = 1;
    //         speech.rate = 1;
    //         speech.pitch = 1;
    //
    //         window.speechSynthesis.speak(speech);
    //     }
    //
    //     readOutLoud("Did you first download the .csv file? If not, press Cancel!")
    // }, [])

    const submit = () => {
        if (window.confirm('Did you first download the .csv file? If not, press "Cancel"!')) {
            // Save it!
            setIsButtonDisabled(true)
            setButtonDescription('Loading...')
            let jobTitle = document.getElementById('jobTitle').value
            let jobUid = document.getElementById('jobUid').value

            props.firebase
                .doAddNewCandidates(jobTitle, jobUid, tableData)
                .then((res) => {
                    if(res == 'Finished') {
                        setIsButtonDisabled(false)
                        setButtonDescription('Add Candidates')
                        alert('Candidates added successfully')
                        window.location.reload();
                    }
                })
                .catch(error => {
                    alert(error)
                })
        } else {
            // Do nothing!
            console.log('Canceled');
        }
    };

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Add New Candidate" subtitle="Add Candidate" className="text-sm-left" />
            </Row>

            <Col lg="12">
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Candidate Details</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col>
                                    <Form>
                                        <Row form>
                                            {/* NEEDS REVISION AND ADDING THE JOBS DROPDOWN */}
                                            <Col md="4" className="form-group">
                                                <label htmlFor="feInputState">Airline's Name</label>
                                                <FormSelect id="clientName" onChange={(e) => setAirline(e.target.value)}>
                                                    <option>Choose...</option>
                                                    {
                                                        airlines.filter(airline => !airline['name'].includes('Admin')).map((airline) => (
                                                            <option>{airline['name']}</option>
                                                        ))
                                                    }
                                                </FormSelect>
                                            </Col>
                                            {
                                                airline == '' ?
                                                    <></>
                                                    :
                                                    <Col md="4" className="form-group">
                                                        <label htmlFor="feInputState">Airline's UID</label>
                                                        <FormInput
                                                            id="uid"
                                                            value={airlineUid}
                                                            disabled={true}
                                                        />
                                                    </Col>
                                            }
                                        </Row>

                                        <Row form>
                                            {
                                                jobs.length == 0 ?
                                                    <></>
                                                    :
                                                    <Col md="4" className="form-group">
                                                        <label htmlFor="feInputState">Airline's Jobs</label>
                                                        <FormSelect id="jobTitle" onChange={(e) => {
                                                            setJob(e.target.value)
                                                            setJobUid(jobs[e.target.selectedIndex-1].uid)
                                                        }}>
                                                            <option>Choose...</option>
                                                            {
                                                                jobs.map((jobb) => (
                                                                    <option>{jobb.title}</option>
                                                                ))
                                                            }
                                                        </FormSelect>
                                                    </Col>
                                            }
                                            {
                                                jobUid == '' ?
                                                    <></>
                                                    :
                                                    <Col md="4" className="form-group">
                                                        <label htmlFor="feInputState">Job's UID</label>
                                                        <FormInput
                                                            id="jobUid"
                                                            value={jobUid}
                                                            disabled={true}
                                                        />
                                                    </Col>
                                            }
                                        </Row>
                                        <Row form>
                                            <DropzoneArea
                                                acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                                onChange={handleSave}
                                                filesLimit={1}
                                                onSave={handleSave}
                                                onClose={handleClose}
                                                open={open}
                                            />

                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Candidates"}
                                                    data={tableData}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Row>
                <Col lg="4">
                    <Button theme="accent" size="lg" style={{marginLeft: '5%'}}
                            disabled={isButtonDisabled}
                            onClick={submit}>{buttonDescription}</Button>
                </Col>
            </Row>
        </>

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {content}
        </Container>
    )
}

const AddCandidateForm = compose(
    withFirebase,
)(AddNewCandidate);

export default AddCandidateForm;
