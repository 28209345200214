import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Form,
    FormSelect,
    FormInput
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";

import { SketchPicker } from 'react-color'
import {DropzoneArea} from "material-ui-dropzone";
import Loader from "react-loader-spinner";
import UserAccountDetails from "../../components/user-profile-lite/UserAccountDetails";

function AppSupport(props) {

    const [name, setName] = useState('')

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            <Row noGutters className="page-header py-4" style={{justifyContent: 'center', textAlign: 'center'}}>
                <p className="text-sm-left" style={{
                    fontSize: '1.825rem',
                    fontWeight: 600,
                    lineHeight: 1,
                    margin: 0,
                    padding: 0,
                    color: '#3d5170',
                    marginTop: 40
                }}>App Support</p>
            </Row>
            <Row style={{justifyContent: 'center', marginTop: 20}}>
                    <Col md={4}>
                        <p className="text-sm-left" style={{
                            fontSize: '1.225rem',
                            fontWeight: 300,
                            lineHeight: 2,
                            color: 'light-grey',
                            marginBottom: 20
                        }}>Screen.hr is an app used for the assessment of candidates.
                            If you experience any problems installing or using our app, you can submit your request for support here:</p>
                        <p className="text-sm-left" style={{
                            fontSize: '1.225rem',
                            fontWeight: 300,
                            lineHeight: 1,
                            color: 'light-grey',
                            marginBottom: 20
                        }}><b>By phone:</b> +852 5801 6677</p>
                        <p className="text-sm-left" style={{
                            fontSize: '1.225rem',
                            fontWeight: 300,
                            lineHeight: 1,
                            color: 'light-grey',
                            marginBottom: 20
                        }}><b>By e-mail:</b> support@screen.hr</p>
                        <p className="text-sm-left" style={{
                            fontSize: '1.225rem',
                            fontWeight: 300,
                            lineHeight: 1,
                            color: 'light-grey',
                            marginBottom: 20
                        }}>Or, fill in the form below:</p>
                        <UserAccountDetails />
                    </Col>
            </Row>
        </Container>
    )
}

const AppSupportForm = compose(
    withFirebase,
)(AppSupport);

export default AppSupportForm;
