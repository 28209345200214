import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Form,
    FormSelect,
    FormInput, Card, Alert
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";
import NewCandidateDetails from "../../components/user-profile-lite/NewCandidateDetails";
import {DropzoneArea} from "material-ui-dropzone";
import MUIDataTable from "mui-datatables";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from "react-loader-spinner";

function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

function AddNewTest(props) {

    const [loaded, setLoaded] = useState(true)

    const [document_id, setDocument_id] = useState('18hSL05BQ-GiYIdrASkElXuCcEbysw8W0qOR40MJtCJ4');
    const [sheet_name, setSheet_name] = useState('Foaie1');
    const [test_name, setTest_name] = useState('');
    const [api_key, setApi_key] = useState('AIzaSyBzR2fstl1Au7DzBpvcABWCOxgJouFJnNo');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [buttonDescription, setButtonDescription] = useState('Add Test');

    const columns = ["Order No.", "Screen Text", "Action Type"];
    const columns2 = ["Order No.", "Screen Text", "Action Type", "Timer"];
    const columns3 = ["Screen Text", "Correct Answer", "Answer A", "Answer B", "Answer C", "Answer D"];

    const [tableDataInfoScreens, setTableDataInfoScreens] = useState([])
    const [tableDataAssesmentScreens, setTableDataAssesmentScreens] = useState([])
    const [tableDataVideoScreens, setTableDataVideoScreens] = useState([])
    const [tableDataAudioScreens, setTableDataAudioScreens] = useState([])
    const [tableDataEnglishScreens, setTableDataEnglishScreens] = useState([])
    const [tableDataMathScreens, setTableDataMathScreens] = useState([])
    const [tableDataLogicalReasoningScreens, setTableDataLogicalReasoningScreens] = useState([])
    const [tableDataFinishScreens, setTableDataFinishScreens] = useState([])

    const options = {
        filterType: 'checkbox',
    };

    const submit = () => {

        setIsButtonDisabled(true)
        setButtonDescription('Loading...')

        props.firebase
            .doAddNewTest(test_name, tableDataInfoScreens, tableDataAssesmentScreens, tableDataVideoScreens, tableDataAudioScreens,
                tableDataEnglishScreens, tableDataMathScreens, tableDataLogicalReasoningScreens, tableDataFinishScreens)
            .then((res) => {
                if(res == 'Finished') {
                    setIsButtonDisabled(false)
                    setButtonDescription('Add Test')
                    alert('Test added successfully')
                    window.location.reload();
                }
            })
            .catch(error => {
                alert(error)
            })
    };

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Add New Test" subtitle="Add Test" className="text-sm-left" />
            </Row>

            <Col lg="12">
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Test Details</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col>
                                    <Form>
                                        <Row form>
                                            <Col md="4" className="form-group">
                                                <label htmlFor="feInputState">Document ID</label>
                                                <FormInput
                                                    id="document_id"
                                                    value={document_id}
                                                    onChange={e => setDocument_id(e.target.value)}
                                                />
                                            </Col>
                                            <Col md="4" className="form-group">
                                                <label htmlFor="feInputState">Sheet Name/ID</label>
                                                <FormInput
                                                    id="sheet_name"
                                                    value={sheet_name}
                                                    onChange={e => setSheet_name(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="4" className="form-group">
                                                <label htmlFor="feInputState">API Key</label>
                                                <FormInput
                                                    id="api_key"
                                                    value={api_key}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md="4" className="form-group">
                                                <label htmlFor="feInputState">Test Name</label>
                                                <FormInput
                                                    id="test_name"
                                                    value={test_name}
                                                    onChange={e => setTest_name(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <Button theme="accent" size="md" style={{marginBottom: '5%'}}
                                                        onClick={() => {
                                                            fetch(`https://sheets.googleapis.com/v4/spreadsheets/${document_id}/values/${sheet_name}?key=${api_key}`)
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    let server_response = data.values

                                                                    // Info Screens
                                                                    let infoScreens_tableData_aux = server_response.filter(entry => entry[0] == "InfoScreens")
                                                                    let infoScreens_tableData = []
                                                                    infoScreens_tableData_aux.forEach(entry => {
                                                                        infoScreens_tableData.push(entry.slice(1, entry.length))
                                                                    })
                                                                    setTableDataInfoScreens(infoScreens_tableData)

                                                                    // Assesment Screens
                                                                    let assesmentScreens_tableData_aux = server_response.filter(entry => entry[0] == "AssesmentScreens")
                                                                    let assesmentScreens_tableData = []
                                                                    assesmentScreens_tableData_aux.forEach(entry => {
                                                                        assesmentScreens_tableData.push(entry.slice(1, entry.length))
                                                                    })
                                                                    setTableDataAssesmentScreens(assesmentScreens_tableData)

                                                                    // Video Screens
                                                                    let videoScreens_tableData_aux = server_response.filter(entry => entry[0] == "VideoQuestions")
                                                                    let videoScreens_tableData = []
                                                                    videoScreens_tableData_aux.forEach(entry => {
                                                                        videoScreens_tableData.push(entry.slice(1, entry.length))
                                                                    })
                                                                    setTableDataVideoScreens(videoScreens_tableData)

                                                                    // Audio Screens
                                                                    let audioScreens_tableData_aux = server_response.filter(entry => entry[0] == "AudioQuestions")
                                                                    let audioScreens_tableData = []
                                                                    audioScreens_tableData_aux.forEach(entry => {
                                                                        audioScreens_tableData.push(entry.slice(1, entry.length))
                                                                    })
                                                                    setTableDataAudioScreens(audioScreens_tableData)

                                                                    // English Screens
                                                                    let englishScreens_tableData_aux = server_response.filter(entry => entry[0] == "English")
                                                                    let englishScreens_tableData = []
                                                                    englishScreens_tableData_aux.forEach(entry => {
                                                                        englishScreens_tableData.push(removeItemAll(entry, "").slice(1, entry.length))
                                                                    })
                                                                    setTableDataEnglishScreens(englishScreens_tableData)

                                                                    // Math Screens
                                                                    let mathScreens_tableData_aux = server_response.filter(entry => entry[0] == "Math")
                                                                    let mathScreens_tableData = []
                                                                    mathScreens_tableData_aux.forEach(entry => {
                                                                        mathScreens_tableData.push(removeItemAll(entry, "").slice(1, entry.length))
                                                                    })
                                                                    setTableDataMathScreens(mathScreens_tableData)

                                                                    // Logical Reasoning Screens
                                                                    let logicalReasoningScreens_tableData_aux = server_response.filter(entry => entry[0] == "LogicalReasoning")
                                                                    let logicalReasoningScreens_tableData = []
                                                                    logicalReasoningScreens_tableData_aux.forEach(entry => {
                                                                        logicalReasoningScreens_tableData.push(removeItemAll(entry, "").slice(1, entry.length))
                                                                    })
                                                                    setTableDataLogicalReasoningScreens(logicalReasoningScreens_tableData)

                                                                    // Finish Screens
                                                                    let finishScreens_tableData_aux = server_response.filter(entry => entry[0] == "FinishScreens")
                                                                    let finishScreens_tableData = []
                                                                    finishScreens_tableData_aux.forEach(entry => {
                                                                        finishScreens_tableData.push(removeItemAll(entry, "").slice(1, entry.length))
                                                                    })
                                                                    setTableDataFinishScreens(finishScreens_tableData)
                                                                });
                                                        }}>See Google Sheet Contents</Button>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Intro Screens"}
                                                    data={tableDataInfoScreens}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Assesment Screens"}
                                                    data={tableDataAssesmentScreens}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Video Screens"}
                                                    data={tableDataVideoScreens}
                                                    columns={columns2}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Audio Screens"}
                                                    data={tableDataAudioScreens}
                                                    columns={columns2}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"English Screens"}
                                                    data={tableDataEnglishScreens}
                                                    columns={columns3}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Math Screens"}
                                                    data={tableDataMathScreens}
                                                    columns={columns3}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Logical Reasoning Screens"}
                                                    data={tableDataLogicalReasoningScreens}
                                                    columns={columns3}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <MUIDataTable
                                                    title={"Finish Screens"}
                                                    data={tableDataFinishScreens}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Row>
                <Col lg="4">
                    <Button theme="accent" size="lg" style={{marginLeft: '5%'}}
                            disabled={isButtonDisabled}
                            onClick={submit}>{buttonDescription}</Button>
                </Col>
            </Row>
        </>

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {content}
        </Container>
    )
}

const AddTestForm = compose(
    withFirebase,
)(AddNewTest);

export default AddTestForm;
