import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import NewJobDetails from "../../components/user-profile-lite/NewJobDetails";
import NewJobAssesors from "../../components/user-profile-lite/NewJobAssesors";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";
import Loader from "react-loader-spinner";

function CreateNewJob(props) {

    const [airlines, setAirlines] = useState([])
    const [tests, setTests] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [buttonDescription, setButtonDescription] = useState('Publish Job')
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        async function getData() {
            let airlines = await props.firebase.doGetAirlines();
            let tests = await props.firebase.doGetAllTestNames();
            console.log("Tests: ", tests)
            setTests(tests)
            setAirlines(airlines)
            setLoaded(true)
        }

        getData().then(r => console.log('Success!'));

    }, [props]);

    let content = loaded == false ?
        <div style={{height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader
                type="Plane"
                color='#333366'
                secondaryColor='#333366'
                height={10}
                width={10}
            />
        </div>
        :
        <>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Add New Job" subtitle="New Job" className="text-sm-left" />
            </Row>

            <Col lg="10">
                <NewJobDetails airlines={airlines} tests={tests}/>
            </Col>
            <Row>
                <Col lg="4">
                    {/*<NewJobAssesors />*/}
                    <Button theme="accent" size="lg" style={{marginLeft: '5%'}} disabled={isButtonDisabled} onClick={() => {
                        setIsButtonDisabled(true)
                        setButtonDescription('Loading...')
                        let clientName = document.getElementById('clientName').value
                        let clientUid = document.getElementById('uid').value
                        let test = document.getElementById('test').value
                        let jobName = document.getElementById('name').value
                        let description = document.getElementById('description').value
                        let endDate = document.getElementById('endDate').value
                        let noOfHires = document.getElementById('noOfHires').value
                        let noOfCandidatesInvited = document.getElementById('noOfCandidatesInvited').value
                        let requiredCredits = document.getElementById('requiredCredits').value
                        let costtest = document.getElementById('costtest').value

                        clientName != '' && jobName != '' && description != '' && endDate != '' && noOfHires != ''
                        && noOfCandidatesInvited != '' && costtest != '' && test != '' ?
                            props.firebase
                                .doCreateNewJob(clientName, clientUid, jobName, description, endDate, noOfHires, noOfCandidatesInvited
                                    ,requiredCredits, costtest, test)
                                .then(() => {
                                    setIsButtonDisabled(false)
                                    setButtonDescription('Publish Job')
                                    alert('Job published successfully')
                                    window.location.reload();
                                })
                                .catch(error => {
                                    alert(error)
                                })
                            :
                            alert('All fields are required')
                    }}>{buttonDescription}</Button>
                </Col>
                {/*<Col lg="4">*/}
                {/*    /!*<NewJobAssesors />*!/*/}
                {/*    <Button theme="accent" size="lg" style={{marginLeft: '5%'}} onClick={() => {*/}
                {/*            props.firebase*/}
                {/*                .doCopyCollections()*/}
                {/*                .then(() => {*/}
                {/*                    window.location.reload();*/}
                {/*                })*/}
                {/*    }}>Copy collections</Button>*/}
                {/*</Col>*/}
            </Row>
        </>

    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {content}
        </Container>
    )
}

const CreateNewJobForm = compose(
    withFirebase,
)(CreateNewJob);

export default CreateNewJobForm;
