import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import RangeDatePicker from "../../components/common/RangeDatePicker";

function NewJobAssesors({ title }) {

  const [name1, setName1] = useState('');
  const [name2, setName2] = useState('');
  const [name3, setName3] = useState('');

  return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="feInputState">Assesor 1</label>
                      <FormInput
                          id="name1"
                          placeholder="eq: Name Surname"
                          value={name1}
                          onChange={e => setName1(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="feInputState">Assesor 2</label>
                      <FormInput
                          id="name2"
                          placeholder="eq: Name Surname"
                          value={name2}
                          onChange={e => setName2(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="feInputState">Assesor 3</label>
                      <FormInput
                          id="name3"
                          placeholder="eq: Name Surname"
                          value={name3}
                          onChange={e => setName3(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
  )
}

NewJobAssesors.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NewJobAssesors.defaultProps = {
  title: "Job Assesors"
};

export default NewJobAssesors;
