import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
} from "shards-react";
import RangeDatePicker from "../../components/common/RangeDatePicker";

function NewJobDetails(props, { title }) {
  const [test, setTest] = useState("");
  const [airline, setAirline] = useState("");
  const [airlineUid, setAirlineUid] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [noOfHires, setNoOfHires] = useState("");
  const [noOfCandidatesInvited, setNoOfCandidatesInvited] = useState("");
  const [requiredCredits, setRequiredCredits] = useState("");
  const [remainingCredits, setRemainingCredits] = useState("");
  const [costtest, setCosttest] = useState("");

  useEffect(() => {
    props.airlines.forEach((each) => {
      each["name"] === airline
        ? setAirlineUid(each["uid"])
        : console.log("Nope");
    });
  });

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Job Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="4" className="form-group">
                    <label htmlFor="feInputState">Client's Name</label>
                    <FormSelect
                      id="clientName"
                      onChange={(e) => setAirline(e.target.value)}
                    >
                      <option>Choose...</option>
                      {props.airlines
                        .filter((airline) => !airline["name"].includes("Admin"))
                        .map((airline) => (
                          <option>{airline["name"]}</option>
                        ))}
                    </FormSelect>
                  </Col>
                  {airline == "" ? (
                    <></>
                  ) : (
                    <Col md="4" className="form-group">
                      <label htmlFor="feInputState">Client's UID</label>
                      <FormInput id="uid" value={airlineUid} disabled={true} />
                    </Col>
                  )}
                </Row>
                {airlineUid == "" ? (
                  <></>
                ) : (
                  <Row form>
                    <Col md="4" className="form-group">
                      <label htmlFor="feInputState">Test Name</label>
                      <FormSelect
                        id="test"
                        onChange={(e) => setTest(e.target.value)}
                      >
                        <option>Choose...</option>
                        {props.tests.map((test) => (
                          <option>{test}</option>
                        ))}
                      </FormSelect>
                    </Col>
                  </Row>
                )}
                <Row form>
                  {/* First Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">Job Name</label>
                    <FormInput
                      id="name"
                      placeholder="eq: 30 Cabin Crew"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                  {/* Last Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feLastName">Description</label>
                    <label
                      htmlFor="feLastName"
                      style={{
                        color:
                          description.length == 80
                            ? "rgba(255,0,0,0.78)"
                            : "gray",
                        float: "right",
                      }}
                    >
                      {description.length}/80
                    </label>
                    <FormInput
                      id="description"
                      placeholder="eq: Short description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength="80"
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Dates */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Dates</label>
                    <RangeDatePicker id="date" value />
                  </Col>
                  {/* No of hires */}
                  <Col md="3" className="form-group">
                    <label htmlFor="fePassword">No. of hires</label>
                    <FormInput
                      id="noOfHires"
                      placeholder="eq: 10"
                      value={noOfHires}
                      onChange={(e) => setNoOfHires(e.target.value)}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label htmlFor="fePassword">
                      No. of invited candidates
                    </label>
                    <FormInput
                      id="noOfCandidatesInvited"
                      placeholder="eq: 50"
                      value={noOfCandidatesInvited}
                      onChange={(e) => setNoOfCandidatesInvited(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Required Credits */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feCity">Required Credits</label>
                    <FormInput
                      id="requiredCredits"
                      placeholder="eq: 100"
                      value={requiredCredits}
                      onChange={(e) => setRequiredCredits(e.target.value)}
                    />
                  </Col>

                  <Col md="6" className="form-group">
                    <label htmlFor="feCity">Cost/Test (credits)</label>
                    <FormInput
                      id="costtest"
                      placeholder="eq: 8"
                      value={costtest}
                      onChange={(e) => setCosttest(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

NewJobDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

NewJobDetails.defaultProps = {
  title: "Job Details",
};

export default NewJobDetails;
