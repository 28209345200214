import React, { useEffect, useState } from "react";
import PropTypes, { string } from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
} from "shards-react";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { DropzoneArea } from "material-ui-dropzone";
import MUIDataTable from "mui-datatables";

function NewCandidateDetails(props, { title }) {
  const [airline, setAirline] = useState("");
  const [airlineUid, setAirlineUid] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [age, setAge] = useState("");
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState([]);
  const [jobUid, setJobUid] = useState([]);

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [texts, setTexts] = useState([]);

  const columns = [
    "First Name",
    "Last Name",
    "City",
    "DOB",
    "Email",
    "Applied for",
    "Username",
    "Password",
  ];

  const [tableData, setTableData] = useState([]);

  const options = {
    filterType: "checkbox",
  };

  useEffect(() => {
    props.airlines.forEach((each) => {
      each["name"] === airline
        ? setAirlineUid(each["uid"])
        : console.log("Nope");
    });

    async function fetchAirlineJobs() {
      let jobList = await props.firebase.doFetchAirlineJobsByUid(airlineUid);
      console.log("jobList -----------------------?", jobList);
      return jobList;
    }

    if (airline != "" && jobs.length == 0) {
      fetchAirlineJobs().then((res) => setJobs(res));
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (acceptedFiles) => {
    setFiles(acceptedFiles);
    setOpen(false);

    acceptedFiles.length == 0
      ? setTableData([])
      : acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onload = () => {
            const str = reader.result;

            // update files contents
            setTexts((t) => [...t, str]);
          };
          reader.readAsText(file);
        });
  };

  useEffect(() => {
    let stringged_csv = String(texts[texts.length - 1]).replace(/\n/g, ",");
    let arrayed_csv = stringged_csv.split(",");
    const array_chunks = (array, chunk_size) =>
      Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map((begin) => array.slice(begin, begin + chunk_size));
    let chunked_csv_array = array_chunks(arrayed_csv, 6);

    // Alter the last field on each array (Shouldn't be \r at the end)
    chunked_csv_array.forEach((chunk) => {
      String(chunk[5]).replace(/[\r]/g, "");
    });

    // Alter data with username and pass (add in each array a field for these)
    chunked_csv_array.forEach((chunk, index) => {
      if (index == 0) {
        chunk[6] = "Username";
        chunk[7] = "Password";
      } else {
        chunk[6] =
          chunk[0] + chunk[1] + Math.floor(100000 + Math.random() * 900000);
        chunk[7] = "QA" + Math.floor(100000 + Math.random() * 900000);
      }
    });

    // Present mui datatable with all these datas
    chunked_csv_array.shift();
    setTableData(chunked_csv_array);
    localStorage.setItem("tableData", JSON.stringify(chunked_csv_array));

    // TO DO: Let dropzone handle only 1 file (see documentation)
  }, [texts]);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Candidate Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* NEEDS REVISION AND ADDING THE JOBS DROPDOWN */}
                  <Col md="4" className="form-group">
                    <label htmlFor="feInputState">Airline's Name</label>
                    <FormSelect
                      id="clientName"
                      onChange={(e) => setAirline(e.target.value)}
                    >
                      <option>Choose...</option>
                      {props.airlines.map((airline) => (
                        <option>{airline["name"]}</option>
                      ))}
                    </FormSelect>
                  </Col>
                  {airline == "" ? (
                    <></>
                  ) : (
                    <Col md="4" className="form-group">
                      <label htmlFor="feInputState">Airline's UID</label>
                      <FormInput id="uid" value={airlineUid} disabled={true} />
                    </Col>
                  )}
                </Row>

                <Row form>
                  {jobs.length == 0 ? (
                    <></>
                  ) : (
                    <Col md="4" className="form-group">
                      <label htmlFor="feInputState">Airline's Jobs</label>
                      <FormSelect
                        id="jobTitle"
                        onChange={(e) => {
                          setJob(e.target.value);
                          setJobUid(jobs[e.target.selectedIndex - 1].uid);
                        }}
                      >
                        <option>Choose...</option>
                        {jobs.map((jobb) => (
                          <option>{jobb.title}</option>
                        ))}
                      </FormSelect>
                    </Col>
                  )}
                  {jobUid == "" ? (
                    <></>
                  ) : (
                    <Col md="4" className="form-group">
                      <label htmlFor="feInputState">Job's UID</label>
                      <FormInput id="jobUid" value={jobUid} disabled={true} />
                    </Col>
                  )}
                </Row>
                <Row form>
                  <DropzoneArea
                    acceptedFiles={[
                      ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                    ]}
                    onChange={handleSave}
                    filesLimit={1}
                    onSave={handleSave}
                    onClose={handleClose}
                    open={open}
                  />
                </Row>
                <Row form>
                  <Col lg={12}>
                    <MUIDataTable
                      title={"Candidates"}
                      data={tableData}
                      columns={columns}
                      options={options}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

NewCandidateDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

NewCandidateDetails.defaultProps = {
  title: "Job Details",
};

const NewCandidateDetailsForm = compose(withFirebase)(NewCandidateDetails);

export default NewCandidateDetailsForm;
