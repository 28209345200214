import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {compose} from "recompose";
import {withFirebase} from "../Firebase";

class MainFooter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      logo: 'https://firebasestorage.googleapis.com/v0/b/sst-dev201109.appspot.com/o/Screen.hr%2Flogo.png?alt=media&token=1e36df83-b22d-4c64-ae44-2d3579cf236b'
    }
  }

  render() {
    return (
        <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
          <Container fluid={false}>
            <Row>
              <Nav>
                {[].map((item, idx) => (
                    <NavItem key={idx}>
                      <NavLink tag={Link} to={item.to}>
                        {item.title}
                      </NavLink>
                    </NavItem>
                ))}
              </Nav>
              <img className="copyright ml-auto my-auto mr-2" src={this.state.logo} alt="logo" width="100px"/>
              {/*<span className="copyright ml-auto my-auto mr-2">{this.state.logo}</span>*/}
            </Row>
          </Container>
        </footer>
    )
  }
}

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © 2021 screen.hr",
  menuItems: [

  ]
};

const MainFooterForm = compose(
    withFirebase,
)(MainFooter);

export default MainFooterForm;
