import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import ReactDOM from "react-dom";

import uuid from "uuid-random";

const config = {
  apiKey: "AIzaSyAj5KyMj_rM2rmPlImntMOPNCR4JA0n4a4",
  authDomain: "sst-dev201109.firebaseapp.com",
  databaseURL: "https://sst-dev201109.firebaseio.com",
  projectId: "sst-dev201109",
  storageBucket: "sst-dev201109.appspot.com",
  messagingSenderId: "350291839698",
  appId: "1:350291839698:web:1f6103736cc31735793b0b",
  measurementId: "G-VSSVLYB5NF",
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();
    this.batch = this.db.batch();
  }

  // *** Auth API ***
  doGetCurrentUserUid() {
    return new Promise((resolve, reject) => {
      console.log(this.auth.currentUser.uid);
    });
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (props, email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).then(() => {
      if (
        this.auth.currentUser.uid == "7gRa304gJ3PCHXUi0UPd4jDtpq13" ||
        this.auth.currentUser.uid == "SHJN27aXuRPnjTLKOqcT9jZbZ3k2" ||
        this.auth.currentUser.uid == "8eBZmEsPFgaVNHjGyWWQ2AMBDDL2"
      ) {
        localStorage.setItem("currentUserUid", this.auth.currentUser.uid);
        props.history.push({
          pathname: "/admin-create-new-job",
        });
      } else {
        localStorage.setItem("currentUserUid", this.auth.currentUser.uid);
        props.history.push({
          pathname: "/dashboard",
          state: { email: email, uid: this.auth.currentUser.uid },
        });
      }
    });

  doGetAirlineUidUsingCurrentUserUid(currentUserUid) {
    return new Promise((resolve, reject) => {
      this.db
        .collection("Airlines")
        .doc(currentUserUid)
        .get()
        .then((res) => {
          if (typeof res.data() != "undefined") {
            resolve(res.data()["uid"]);
          } else {
            this.db
              .collection("Assesors")
              .doc(currentUserUid)
              .get()
              .then((res2) => {
                resolve(res2.data()["hiredBy"]);
              });
          }
        });
    });
  }

  doSignInWithPhone = (props, email, password, number, recaptcha, container) =>
    this.auth
      .signInWithPhoneNumber(number, recaptcha)
      .then((e) => {
        let code = prompt("Please enter the code sent to your mobile number");

        if (code === null) return;

        e.confirm(code)
          .then((result) => {
            // this.doSignInWithEmailAndPassword(email, password).then(r => {
            if (
              localStorage.getItem("currentUserUid") ==
                "7gRa304gJ3PCHXUi0UPd4jDtpq13" ||
              localStorage.getItem("currentUserUid") ==
                "SHJN27aXuRPnjTLKOqcT9jZbZ3k2" ||
              localStorage.getItem("currentUserUid") ==
                "8eBZmEsPFgaVNHjGyWWQ2AMBDDL2"
            ) {
              // localStorage.setItem('currentUserUid', '7druy2RD2gMqdZKmbxmR2grbt2j1');
              props.history.push({
                pathname: "/admin-create-new-job",
              });
            } else {
              // localStorage.setItem('currentUserUid', this.auth.currentUser.uid);
              props.history.push({
                pathname: "/dashboard",
                state: { email: email, uid: this.auth.currentUser.uid },
              });
            }
            // })
          })
          .catch(function(error) {
            alert(error + " Could not verify,Please try again");
          });
      })
      .catch(function(error) {
        alert(
          "Please try again. We were unable to reach your phone.Select the correct code and the phone number"
        );
      });

  doSignOut = () => {
    this.auth.signOut().then((r) => localStorage.setItem("currentUserUid", ""));
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doGetAirlines() {
    return new Promise((resolve, reject) => {
      let airlines = [];
      this.db.collection("Airlines").onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          console.log("doc----------------?", doc);
          airlines.push(doc.data());
        });
        resolve(airlines);
      });
    });
  }

  doGetAllTestNames() {
    return new Promise(async (resolve, reject) => {
      const snapshot = await this.db
        .collection("Tests")
        .get()
        .then((querySnapshot) => {
          const tempDoc = [];
          querySnapshot.forEach((doc) => {
            tempDoc.push({ id: doc.id, ...doc.data() });
          });
          resolve(tempDoc.map((each) => each.id));
        });
    });
  }

  doGetPurchasesHistory() {
    return new Promise((resolve, reject) => {
      let purchases = [];
      this.db.collection("Credits").onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          purchases.push(doc.data());
        });
        resolve(purchases);
      });
    });
  }

  doGetMyCreditsPurchases() {
    return new Promise(async (resolve, reject) => {
      let airlineUid = await this.doGetAirlineUidUsingCurrentUserUid(
        localStorage.getItem("currentUserUid")
      );
      this.db
        .collection("Airlines")
        .doc(airlineUid)
        .get()
        .then((res) => {
          let credits = [];
          let counter = 0;
          let stop_counter_at = res.data()["purchasedCredits"].length;
          if (stop_counter_at == 0) resolve([]);
          res.data()["purchasedCredits"].forEach((credit) => {
            this.db
              .collection("Credits")
              .doc(credit)
              .get()
              .then((result) => {
                console.log(
                  "result.data -------------------------------------?",
                  result.data()
                );
                credits.push(result.data());
                counter = counter + 1;

                if (counter === stop_counter_at) resolve(credits);
              });
          });
        });
    });
  }

  doGetMySpentCreditsNumber() {
    return new Promise(async (resolve, reject) => {
      let airlineUid = await this.doGetAirlineUidUsingCurrentUserUid(
        localStorage.getItem("currentUserUid")
      );
      this.db
        .collection("Airlines")
        .doc(airlineUid)
        .get()
        .then((res) => {
          let jobs = [];
          let counter = 0;
          let stop_counter_at = res.data()["postedJobs"].length;
          if (stop_counter_at == 0) resolve({ number: 0, infos: [] });
          res.data()["postedJobs"].forEach((job) => {
            this.db
              .collection("Jobs")
              .doc(job)
              .get()
              .then((result) => {
                jobs.push(result.data());
                counter = counter + 1;

                if (counter === stop_counter_at) {
                  let infos = [];
                  jobs.forEach((job) => {
                    infos.push({
                      candidates: job["candidatesForTest1"],
                      creditCost: job["costTestPerCredit"],
                    });
                  });
                  infos = infos.flat(1);

                  let counter = 0;
                  let number = 0;
                  let stopCounterAt = 0;

                  infos.forEach((info) => {
                    if (info["candidates"].length == 0) {
                      resolve({ number: 0, infos: [] });
                    }
                  });

                  infos.forEach((info) => {
                    info["candidates"].forEach((candidate) => {
                      stopCounterAt = stopCounterAt + 1;
                    });
                  });
                  infos.forEach((info) => {
                    info["candidates"].forEach((candidate) => {
                      this.db
                        .collection("Results")
                        .where("candidateUsername", "==", candidate)
                        .where("assesment", "!=", "Test not taken")
                        .get()
                        .then((querySnapshot) => {
                          counter = counter + 1;
                          number = number + querySnapshot.size;

                          if (counter == stopCounterAt) {
                            resolve({ number: number, infos: infos });
                          }
                        });
                    });
                  });
                }
              });
          });
        });
    });
  }

  doGetMySpentCredits(spentCreditsNumber, infos) {
    return new Promise((resolve, reject) => {
      let counter = 0;
      let results = [];

      if (infos.length == 0) {
        resolve([]);
      }

      infos.forEach((info) => {
        info["candidates"].forEach((candidate) => {
          this.db
            .collection("Results")
            .where("candidateUsername", "==", candidate)
            .where("assesment", "!=", "Test not taken")
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach(function(doc) {
                results.push({
                  candidateData: doc.data(),
                  creditCost: info["creditCost"],
                });
                counter = counter + 1;

                console.log(
                  "Spent: ",
                  spentCreditsNumber,
                  " , Candidate: ",
                  counter
                );
                if (counter === spentCreditsNumber) {
                  resolve(results);
                }
              });

              if (querySnapshot.length === 0) {
                resolve([]);
              }
            });
        });
      });
    });
  }

  async doRedesignTests() {
    const citiesRef = this.db
      .collection("Tests")
      .doc("Test1_Math")
      .collection("Test1_Math");
    const snapshot = await citiesRef.get();
    snapshot.forEach((doc) => {
      console.log(doc.data());
      this.db
        .collection("Tests")
        .doc("Test_1")
        .collection("Test1_Math")
        .doc(doc.id)
        .set(doc.data());
    });
  }

  async doAddClientLogo(name, logo) {
    const uploadAsImage = async (uri, progressCallback) => {
      const ref = this.storage.ref().child(`Airlines/${name}/logo.jpg`);

      const task = ref.put(uri);

      return new Promise(async (resolve, reject) => {
        task.on(
          "state_changed",
          (snapshot) => {
            progressCallback &&
              progressCallback(snapshot.bytesTransferred / snapshot.totalBytes);
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) =>
            reject(error) /* this is where you would put an error callback! */,
          () => {
            task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    };

    return new Promise(async (resolve, reject) => {
      await uploadAsImage(logo).then((res) => resolve(res));
    });
  }

  async doAddNewClient(name, code, email, pass, hex, textHex, logo_uri) {
    return new Promise(async (resolve, reject) => {
      let airlines = [];
      await this.db
        .collection("Airlines")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            airlines.push(doc.data()["name"]);
          });

          if (airlines.includes(name)) {
            reject(
              alert("This airline's name is already used by another account.")
            );
          } else {
            this.auth
              .createUserWithEmailAndPassword(email, pass)
              .then(async () => {
                let currentUserUid = this.auth.currentUser.uid;

                console.log(currentUserUid);
                console.log(logo_uri);
                await this.db
                  .collection("Airlines")
                  .doc(currentUserUid)
                  .set({
                    creditsLeft: 0,
                    email: email,
                    hex: hex,
                    textHex: textHex,
                    code: code,
                    logo: logo_uri,
                    name: name,
                    postedJobs: [],
                    purchasedCredits: [],
                    uid: currentUserUid,
                  })
                  .then(() => resolve("Success!"))
                  .catch((error) => alert(error));
              })
              .catch((err) => {
                reject(
                  alert(
                    "The email you have entered is already used by another account."
                  )
                );
              });
          }
        });
    });
  }

  doAddNewCandidates(jobTitle, jobUid, tableData) {
    return new Promise((resolve, reject) => {
      let candidateNo = 0;
      let rowsNo = tableData.length;

      tableData.forEach(async (row, index) => {
        let snapshot = await this.db
          .collection("Candidates")
          .where("email", "==", row[4])
          .get();

        if (snapshot.empty) {
          console.log("No matching documents.");
          this.auth
            .createUserWithEmailAndPassword(row[4], row[8])
            .then(async () => {
              // Add row to as an entry in 'Candidates' and 'Results'
              let candidateUid = uuid();
              let resultsUid = uuid();

              let docRef = await this.db
                .collection("Candidates")
                .doc(candidateUid);
              let resultDocRef = await this.db
                .collection("Results")
                .doc(resultsUid);

              await this.batch.set(docRef, {
                email: row[4],
                jobsAppliedFor: [jobUid],
                name: row[0] + " " + row[1],
                uid: candidateUid,
                usedUsernames: [],
                type: row[6],
                usernames: [row[7]],
              });
              await this.batch.set(resultDocRef, {
                scores: {
                  video1Appearance: 0,
                  video1Demeanor: 0,
                  video1Verbal: 0,
                  video1Communication: 0,
                  video1Quality: 0,
                  video2Appearance: 0,
                  video2Demeanor: 0,
                  video2Verbal: 0,
                  video2Communication: 0,
                  video2Quality: 0,
                  audio1Pronounciation: 0,
                  audio1Clarity: 0,
                  audio1Fluidity: 0,
                  audio2Pronounciation: 0,
                  audio2Clarity: 0,
                  audio2Fluidity: 0,
                },
                audio1Score: 0,
                audio2Score: 0,
                video1Score: 0,
                video2Score: 0,
                assesment: "Test not taken",
                logicalReasoningScore: 0,
                mathScore: 0,
                englishScore: 0,
                age: 0,
                candidateNumber: 10,
                candidateUsername: row[7],
                city: row[2],
                dateOfBirth: row[3],
                email: row[4],
                firstName: row[0],
                lastName: row[1],
                notes: "",
                testName: jobTitle,
                uid: resultsUid,
              });

              candidateNo = candidateNo + 1;
              if (candidateNo == rowsNo)
                this.batch.commit().then((r) => console.log("Added"));
              //--------------------------------------
            })
            .then(async () => {
              this.db
                .collection("Jobs")
                .where("uid", "==", jobUid)
                .get()
                .then((query) => {
                  const thing = query.docs[0];
                  let currVal = thing.data().candidatesForTest1;
                  let usernames = tableData.map((each) => {
                    return each[7];
                  });
                  const newVal = new Set([...currVal, ...usernames]);
                  thing.ref
                    .update({ candidatesForTest1: Array.from(newVal) })
                    .then((r) => {
                      if (rowsNo == candidateNo) {
                        resolve("Finished");
                      }
                    });
                })
                .catch((e) => console.log("err_1: ", e));
            });
        } else {
          this.db
            .collection("Candidates")
            .where("email", "==", row[4])
            .get()
            .then((query) => {
              let currVal = [];
              try {
                currVal = query.docs[0].data().usernames;
                const newVal = new Set([...currVal, row[7]]);

                query.docs[0].ref
                  .update({ usernames: Array.from(newVal) })
                  .then(async (r) => {
                    let resultsUid = uuid();
                    let resultDocRef = await this.db
                      .collection("Results")
                      .doc(resultsUid);
                    await this.batch
                      .set(resultDocRef, {
                        scores: {
                          video1Appearance: 0,
                          video1Demeanor: 0,
                          video1Verbal: 0,
                          video1Communication: 0,
                          video1Quality: 0,
                          video2Appearance: 0,
                          video2Demeanor: 0,
                          video2Verbal: 0,
                          video2Communication: 0,
                          video2Quality: 0,
                          audio1Pronounciation: 0,
                          audio1Clarity: 0,
                          audio1Fluidity: 0,
                          audio2Pronounciation: 0,
                          audio2Clarity: 0,
                          audio2Fluidity: 0,
                        },
                        audio1Score: 0,
                        audio2Score: 0,
                        video1Score: 0,
                        video2Score: 0,
                        assesment: "Test not taken",
                        logicalReasoningScore: 0,
                        mathScore: 0,
                        englishScore: 0,
                        age: 0,
                        candidateNumber: 10,
                        candidateUsername: row[7],
                        city: row[2],
                        dateOfBirth: row[3],
                        email: row[4],
                        firstName: row[0],
                        lastName: row[1],
                        notes: "",
                        testName: jobTitle,
                        uid: resultsUid,
                      })
                      .then((r) => {
                        candidateNo = candidateNo + 1;
                        if (candidateNo == rowsNo)
                          this.batch.commit().then((r) => console.log("Added"));

                        this.db
                          .collection("Jobs")
                          .where("uid", "==", jobUid)
                          .get()
                          .then((query) => {
                            const thing = query.docs[0];
                            let currVal = thing.data().candidatesForTest1;
                            let usernames = tableData.map((each) => {
                              return each[7];
                            });
                            const newVal = new Set([...currVal, ...usernames]);
                            thing.ref
                              .update({
                                candidatesForTest1: Array.from(newVal),
                              })
                              .then((r) => {
                                if (rowsNo == candidateNo) {
                                  resolve("Finished");
                                }
                              });
                          });
                      });
                  })
                  .catch((err) => console.log("Oops! ", err));
              } catch (e) {
                console.log("Well, at least I tried! ", e);
              }
            })
            .catch((err) => console.log("Wow! Even bigger? ", err));
        }
      });
    });
  }

  doAddNewTest(
    testName,
    info,
    assesment,
    video,
    audio,
    english,
    math,
    logical,
    finish
  ) {
    return new Promise((resolve, reject) => {
      let counter = 0;
      let goal_counter =
        info.length +
        assesment.length +
        video.length +
        audio.length +
        english.length +
        math.length +
        logical.length +
        finish.length;
      // Info Screens
      info.forEach((entry) => {
        let entry_uuid = uuid();
        if (entry[2] == "video") {
          this.db
            .collection("Tests")
            .doc(testName)
            .collection("InfoScreens")
            .doc(entry_uuid)
            .set({
              orderNo: parseInt(entry[0]),
              question: "Introduction video:",
              uri: entry[1],
              type: entry[2],
              height: 245,
              width: 450,
            })
            .then(() => {
              counter = counter + 1;
              if (counter == goal_counter) {
                this.db
                  .collection("Tests")
                  .doc(testName)
                  .set({
                    none: "none",
                  })
                  .then(() => {
                    this.db
                      .collection("Tests")
                      .doc(testName)
                      .update({
                        none: this.db.FieldValue.delete(),
                      })
                      .then((r) => console.log("Done!"));
                  });
                resolve("Finished");
              }
            });
        } else {
          this.db
            .collection("Tests")
            .doc(testName)
            .collection("InfoScreens")
            .doc(entry_uuid)
            .set({
              orderNo: parseInt(entry[0]),
              question: entry[1],
              type: entry[2],
            })
            .then(() => {
              counter = counter + 1;
              if (counter == goal_counter) {
                this.db
                  .collection("Tests")
                  .doc(testName)
                  .set({
                    none: "none",
                  })
                  .then(() => {
                    this.db
                      .collection("Tests")
                      .doc(testName)
                      .update({
                        none: this.db.FieldValue.delete(),
                      })
                      .then((r) => console.log("Done!"));
                  });
                resolve("Finished");
              }
            });
        }
      });

      // Assesment screens
      assesment.forEach((entry) => {
        let entry_uuid = uuid();
        this.db
          .collection("Tests")
          .doc(testName)
          .collection("AssesmentScreens")
          .doc(entry_uuid)
          .set({
            orderNo: parseInt(entry[0]),
            question: entry[1],
            type: entry[2],
          })
          .then(() => {
            counter = counter + 1;
            if (counter == goal_counter) {
              this.db
                .collection("Tests")
                .doc(testName)
                .set({
                  none: "none",
                })
                .then(() => {
                  this.db
                    .collection("Tests")
                    .doc(testName)
                    .update({
                      none: this.db.FieldValue.delete(),
                    })
                    .then((r) => console.log("Done!"));
                });
              resolve("Finished");
            }
          });
      });

      // Video screens
      video.forEach((entry) => {
        let entry_uuid = uuid();
        if (entry[3] == "-") {
          this.db
            .collection("Tests")
            .doc(testName)
            .collection("VideoQuestions")
            .doc(entry_uuid)
            .set({
              orderNo: parseInt(entry[0]),
              question: entry[1],
              timer: entry[2],
            })
            .then(() => {
              counter = counter + 1;
              if (counter == goal_counter) {
                this.db
                  .collection("Tests")
                  .doc(testName)
                  .set({
                    none: "none",
                  })
                  .then(() => {
                    this.db
                      .collection("Tests")
                      .doc(testName)
                      .update({
                        none: this.db.FieldValue.delete(),
                      })
                      .then((r) => console.log("Done!"));
                  });
                resolve("Finished");
              }
            });
        } else {
          this.db
            .collection("Tests")
            .doc(testName)
            .collection("VideoQuestions")
            .doc(entry_uuid)
            .set({
              orderNo: parseInt(entry[0]),
              question: entry[1] == "[video]" ? "" : entry[1],
              timer: entry[2] == "wait" ? "10" : entry[2],
              seconds: parseInt(entry[3]),
            })
            .then(() => {
              counter = counter + 1;
              if (counter == goal_counter) {
                this.db
                  .collection("Tests")
                  .doc(testName)
                  .set({
                    none: "none",
                  })
                  .then(() => {
                    this.db
                      .collection("Tests")
                      .doc(testName)
                      .update({
                        none: this.db.FieldValue.delete(),
                      })
                      .then((r) => console.log("Done!"));
                  });
                resolve("Finished");
              }
            });
        }
      });

      // Audio screens
      audio.forEach((entry) => {
        let entry_uuid = uuid();
        if (entry[3] == "-") {
          this.db
            .collection("Tests")
            .doc(testName)
            .collection("AudioQuestions")
            .doc(entry_uuid)
            .set({
              orderNo: parseInt(entry[0]),
              question: entry[1],
              timer: entry[2],
            })
            .then(() => {
              counter = counter + 1;
              if (counter == goal_counter) {
                this.db
                  .collection("Tests")
                  .doc(testName)
                  .set({
                    none: "none",
                  })
                  .then(() => {
                    this.db
                      .collection("Tests")
                      .doc(testName)
                      .update({
                        none: this.db.FieldValue.delete(),
                      })
                      .then((r) => console.log("Done!"));
                  });
                resolve("Finished");
              }
            });
        } else {
          this.db
            .collection("Tests")
            .doc(testName)
            .collection("AudioQuestions")
            .doc(entry_uuid)
            .set({
              orderNo: parseInt(entry[0]),
              question: entry[1] == "[audio]" ? "" : entry[1],
              timer: entry[2] == "wait" ? "10" : entry[2],
              seconds: parseInt(entry[3]),
            })
            .then(() => {
              counter = counter + 1;
              if (counter == goal_counter) {
                this.db
                  .collection("Tests")
                  .doc(testName)
                  .set({
                    none: "none",
                  })
                  .then(() => {
                    this.db
                      .collection("Tests")
                      .doc(testName)
                      .update({
                        none: this.db.FieldValue.delete(),
                      })
                      .then((r) => console.log("Done!"));
                  });
                resolve("Finished");
              }
            });
        }
      });

      // English screens
      english.forEach((entry) => {
        let entry_uuid = uuid();
        this.db
          .collection("Tests")
          .doc(testName)
          .collection("English")
          .doc(entry_uuid)
          .set({
            question: entry[0],
            correctAnswer: entry[1],
            answers: [entry[2], entry[3], entry[4], entry[5]],
          })
          .then(() => {
            counter = counter + 1;
            if (counter == goal_counter) {
              this.db
                .collection("Tests")
                .doc(testName)
                .set({
                  none: "none",
                })
                .then(() => {
                  this.db
                    .collection("Tests")
                    .doc(testName)
                    .update({
                      none: this.db.FieldValue.delete(),
                    })
                    .then((r) => console.log("Done!"));
                });
              resolve("Finished");
            }
          });
      });

      // Math screens
      math.forEach((entry) => {
        let entry_uuid = uuid();
        this.db
          .collection("Tests")
          .doc(testName)
          .collection("Math")
          .doc(entry_uuid)
          .set({
            question: entry[0],
            correctAnswer: entry[1],
            answers: [entry[2], entry[3], entry[4], entry[5]],
          })
          .then(() => {
            counter = counter + 1;
            if (counter == goal_counter) {
              this.db
                .collection("Tests")
                .doc(testName)
                .set({
                  none: "none",
                })
                .then(() => {
                  this.db
                    .collection("Tests")
                    .doc(testName)
                    .update({
                      none: this.db.FieldValue.delete(),
                    })
                    .then((r) => console.log("Done!"));
                });
              resolve("Finished");
            }
          });
      });

      // Logical reasoning screens
      logical.forEach((entry) => {
        let entry_uuid = uuid();
        this.db
          .collection("Tests")
          .doc(testName)
          .collection("LogicalReasoning")
          .doc(entry_uuid)
          .set({
            question: entry[0],
            correctAnswer: entry[1],
            answers: [entry[2], entry[3], entry[4], entry[5]],
          })
          .then(() => {
            counter = counter + 1;
            if (counter == goal_counter) {
              this.db
                .collection("Tests")
                .doc(testName)
                .set({
                  none: "none",
                })
                .then(() => {
                  this.db
                    .collection("Tests")
                    .doc(testName)
                    .update({
                      none: this.db.FieldValue.delete(),
                    })
                    .then((r) => console.log("Done!"));
                });
              resolve("Finished");
            }
          });
      });

      // Finish screens
      finish.forEach((entry) => {
        let entry_uuid = uuid();
        this.db
          .collection("Tests")
          .doc(testName)
          .collection("FinishScreens")
          .doc(entry_uuid)
          .set({
            orderNo: parseInt(entry[0]),
            question: entry[1],
            type: entry[2],
          })
          .then(() => {
            counter = counter + 1;
            if (counter == goal_counter) {
              this.db
                .collection("Tests")
                .doc(testName)
                .set({
                  none: "none",
                })
                .then(() => {
                  this.db
                    .collection("Tests")
                    .doc(testName)
                    .update({
                      none: this.db.FieldValue.delete(),
                    })
                    .then((r) => console.log("Done!"));
                });
              resolve("Finished");
            }
          });
      });
    });
  }

  doAddNotesToResult(candidateUsername, notes) {
    let results = [];
    return new Promise((resolve, reject) => {
      this.db
        .collection("Results")
        .where("candidateUsername", "==", candidateUsername)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach(function(doc) {
            results.push(doc.data());
          });
          this.db
            .collection("Results")
            .doc(results[0].uid)
            .update({ notes: notes })
            .then((res) => {
              resolve("Notes were added to the results");
            })
            .catch((error) => {
              console.log(error.message);
            });
        });
    });
  }

  doAddAssesorScoreToTest(props, uid, assesorScore) {
    return new Promise((resolve, reject) => {
      this.db
        .collection("Results")
        .doc(uid)
        .update({
          assesorScore: assesorScore,
        })
        .then((res) => {
          resolve("Finished");
        });
    });
  }

  doAddScoringToTest(props, uid, media, scores, assesment) {
    return new Promise((resolve, reject) => {
      this.db
        .collection("Results")
        .doc(uid)
        .update({
          video1Score: media.video1Score,
          video2Score: media.video2Score,
          audio1Score: media.audio1Score,
          audio2Score: media.audio2Score,
          scores: scores,
          assesment: assesment,
        })
        .then((res) => {
          resolve("Finished");
        });
    });
  }

  doAddCreditsToAirline(clientName, credits, notes) {
    return new Promise((resolve, reject) => {
      let airlines = [];
      let uid_credit = uuid();
      this.db
        .collection("Airlines")
        .where("name", "==", clientName)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach(function(doc) {
            airlines.push(doc.data());
          });
          this.db
            .collection("Airlines")
            .doc(airlines[0].uid)
            .update({
              creditsLeft: airlines[0].creditsLeft + parseInt(credits),
              purchasedCredits: [...airlines[0].purchasedCredits, uid_credit],
            })
            .then((res) => {
              this.db
                .collection("Credits")
                .doc(uid_credit)
                .set({
                  client: clientName,
                  creditsNumber: parseInt(credits),
                  date: new Date().toString(),
                  note: notes,
                  uid: uid_credit,
                })
                .then((res) => {
                  resolve("Success!");
                });
            });
        });
    });
  }

  doGetCurrentAirline() {
    return new Promise(async (resolve, reject) => {
      let currentUserUid = await this.doGetAirlineUidUsingCurrentUserUid(
        localStorage.getItem("currentUserUid")
      );

      if (
        currentUserUid
        // currentUserUid != "7gRa304gJ3PCHXUi0UPd4jDtpq13" ||
        // currentUserUid != "SHJN27aXuRPnjTLKOqcT9jZbZ3k2" ||
        // currentUserUid != "8eBZmEsPFgaVNHjGyWWQ2AMBDDL2"
      ) {
        this.db
          .collection("Airlines")
          .doc(currentUserUid)
          .get()
          .then((result) => {
            resolve(result.data());
          });
      } else {
        resolve("Nope");
      }
    });
  }

  doGetAirlinePostedJobs(airlineUid) {
    return new Promise((resolve, reject) => {
      this.db
        .collection("Airlines")
        .doc(airlineUid)
        .get()
        .then((res) => {
          let jobs = [];
          let counter = 0;
          let stop_counter_at = res.data()["postedJobs"].length;
          res.data()["postedJobs"].forEach((job) => {
            this.db
              .collection("Jobs")
              .doc(job)
              .get()
              .then((result) => {
                jobs.push(result.data());
                counter = counter + 1;

                if (counter === stop_counter_at) resolve(jobs);
              });
          });
        });
    });
  }

  doGetCandidatesResults(candidates) {
    let counter = 0;
    let stop_counter_at = candidates.length;
    let results = [];

    let test = [];

    return new Promise((resolve, reject) => {
      if (candidates.length === 0) {
        resolve(results);
      } else {
        candidates.forEach((candidate) => {
          this.db
            .collection("Results")
            .where("candidateUsername", "==", candidate)
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach(function(doc) {
                results.push(doc.data());
                counter = counter + 1;

                test.push(candidate);
              });

              console.log("Lenght: ", test.length);
              if (counter === stop_counter_at) resolve(results);
            });
        });
      }
    });
  }

  doCreateNewJob(
    clientName,
    clientUid,
    jobName,
    description,
    endDate,
    noOfHires,
    noOfCandidatesInvited,
    requiredCredits,
    costtest,
    test
  ) {
    return new Promise((resolve, reject) => {
      let uid_job = uuid();

      let full_data = {
        avatar: "",
        candidatesForTest1: [],
        candidatesForTest2: [],
        hexCode: "",
        interviewingPhase: "Testing for phase 2",
        postedBy: clientUid,
        resultsForTest1: [],
        resultsForTest2: [],
        status: "Active",
        stringEncodedTestQuestions: "",
        title: jobName,
        uid: uid_job,
        test: test,
        description: description,
        // startDate: startDate,
        endDate: endDate,
        numberOfHires: parseInt(noOfHires),
        numberOfCandidatesInvited: parseInt(noOfCandidatesInvited),
        requiredCredits: parseInt(requiredCredits),
        costTestPerCredit: parseInt(costtest),
        assesorName1: "assesorName1",
        assesorName2: "assesorName2",
        assesorName3: "assesorName3",
      };

      this.db
        .collection("Airlines")
        .doc(clientUid)
        .get()
        .then((result) => {
          this.db
            .collection("Airlines")
            .doc(clientUid)
            .update({ postedJobs: [...result.data()["postedJobs"], uid_job] })
            .then((res) => {
              full_data["hexCode"] = result.data()["hex"];
              full_data["textHexCode"] = result.data()["textHex"];
              full_data["avatar"] = result.data()["logo"];
              full_data["airlineName"] = result.data()["name"];
              this.db
                .collection("Jobs")
                .doc(uid_job)
                .set(full_data)
                .then((res) => {
                  resolve("Success!");
                });
            });
        });
    });
  }

  doFetchAirlineJobsByUid(airlineUid) {
    return new Promise((resolve, reject) => {
      this.db
        .collection("Jobs")
        .where("postedBy", "==", airlineUid)
        .get()
        .then(function(res) {
          let counter = 0;
          res.forEach(function(docc) {
            counter = counter + 1;
          });
          let jobs = [];
          res.forEach(function(docc) {
            jobs.push({ title: docc.data().title, uid: docc.data().uid });
            if (jobs.length == counter) resolve(jobs);
          });
        });
    });
  }

  doAddNewAssesor(clientName, clientUid, firstName, lastName, email, pass) {
    return new Promise(async (resolve, reject) => {
      let assesors = [];
      await this.db
        .collection("Assesors")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            assesors.push(doc.data()["email"]);
          });

          if (assesors.includes(email)) {
            reject(
              alert("This assesor's email is already used by another account.")
            );
          } else {
            this.auth
              .createUserWithEmailAndPassword(email, pass)
              .then(async () => {
                let currentUserUid = this.auth.currentUser.uid;

                console.log(currentUserUid);
                await this.db
                  .collection("Assesors")
                  .doc(currentUserUid)
                  .set({
                    uid: currentUserUid,
                    firstName: firstName,
                    lastName: lastName,
                    hiredBy: clientUid,
                    email: email,
                  })
                  .then(() => {
                    this.db
                      .collection("Airlines")
                      .doc(clientUid)
                      .get()
                      .then((res) => {
                        let airline = res.data();

                        this.db
                          .collection("Airlines")
                          .doc(clientUid)
                          .update({
                            assesors:
                              typeof airline["assesors"] != "undefined"
                                ? [...airline["assesors"], currentUserUid]
                                : [currentUserUid],
                          })
                          .then(() => {
                            resolve("Success!");
                          });
                      });
                  })
                  .catch((error) => alert(error));
              })
              .catch((err) => {
                reject(
                  alert(
                    "The email you have entered is already used by another account."
                  )
                );
              });
          }
        });
    });
  }

  doCopyCollections() {
    return new Promise(async (resolve, reject) => {
      const english = await this.db
        .collection("Tests")
        .doc("Test_HKExpress")
        .collection("FinishScreens")
        .get();
      english.forEach(async (doc) => {
        await this.db
          .collection("Tests")
          .doc("Test_Emirates")
          .collection("FinishScreens")
          .doc(doc.uid)
          .set(doc.data());
      });
    });
  }

  doGetAirlineJobs() {
    return new Promise(async (resolve, reject) => {
      let currentUserUid = await this.doGetAirlineUidUsingCurrentUserUid(
        localStorage.getItem("currentUserUid")
      );
      this.db
        .collection("Airlines")
        .doc(currentUserUid)
        .get()
        .then((result) => {
          let counter = 0;
          let stop_counter_at = result.data()["postedJobs"].length;
          let jobs = [];
          if (stop_counter_at == 0) resolve([]);
          result.data()["postedJobs"].forEach((job) => {
            this.db
              .collection("Jobs")
              .doc(job)
              .get()
              .then((result) => {
                jobs.push(result.data());
                if (jobs.length == stop_counter_at) resolve(jobs);
              });
          });
        });
    });
  }

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");
}

export const firebaseApp = firebase;
export default Firebase;
